import React, { useState } from "react";
import ChartRenderer from "../components/ChartRenderer";
import {
  DashboardItem,
  Dashboard,
  ChartDescription,
} from "../components/Utils";
import CONSTANT from "./Constant";

DashboardItem.defaultProps = {
  size: 12,
};

const phoneCallCountQuery = {
  "measures": [
    "Reports.phoneCallCount"
  ],
  "timeDimensions": [
    {
      "dimension": "Reports.date",
      "granularity": "month"
    }
  ],
  "order": {
    "Reports.date": "asc"
  },
  "filters": [
    {
      "member": "Reports.platform",
      "operator": "contains",
      "values": [
        "logicimmo"
      ]
    },
    {
      "member": "Reports.reporttype",
      "operator": "equals",
      "values": [
        "GENERAL"
      ]
    }
  ]
}

const emailCountQuery = {
  "measures": [
    "Reports.emailcount"
  ],
  "timeDimensions": [
    {
      "dimension": "Reports.date",
      "granularity": "month"
    }
  ],
  "order": {
    "Reports.date": "asc"
  },
  "filters": [
    {
      "member": "Reports.platform",
      "operator": "contains",
      "values": [
        "logicimmo"
      ]
    },
    {
      "member": "Reports.reporttype",
      "operator": "equals",
      "values": [
        "GENERAL"
      ]
    }
  ]
}

const viewsCountQuery = {
  "measures": [
    "Reports.viewcount"
  ],
  "timeDimensions": [
    {
      "dimension": "Reports.date",
      "granularity": "month"
    }
  ],
  "order": {
    "Reports.date": "asc"
  },
  "filters": [
    {
      "member": "Reports.platform",
      "operator": "contains",
      "values": [
        "logicimmo"
      ]
    },
    {
      "member": "Reports.reporttype",
      "operator": "equals",
      "values": [
        "GENERAL"
      ]
    }
  ]
}

const LogicimmoPage = () => {
  const [displaySerie, setDisplaySerie] = useState({});

  return (
    <Dashboard key={"logicimmo"} rowKey="logicimmo">
      <DashboardItem
        title={[
          "Phone Calls count for the logicimmo portal by month",
          ChartDescription(
            "Phone calls received for the LogicImmo portal by month",
            "right"
          ),
        ]}
        direction="right"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: phoneCallCountQuery,
            chartType: "line",
            pivotConfig: null,
            dateFormat: "MM/YY"
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Emails count for the logicimmo portal by month",
          ChartDescription(
            "Emails received for the LogicImmo portal by month",
            "right"
          ),
        ]}
        direction="right"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: emailCountQuery,
            chartType: "line",
            pivotConfig: null,
            dateFormat: "MM/YY"
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "View count for the logicimmo portal by month",
          ChartDescription(
            "Number of view for all the properties for the LogicImmo portal by month",
            "right"
          ),
        ]}
        direction="right"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: viewsCountQuery,
            chartType: "line",
            pivotConfig: null,
            dateFormat: "MM/YY"
          },
        })}
      </DashboardItem>
    </Dashboard>
  );
};

export default LogicimmoPage;
