import React, { useState } from "react";
import ChartRenderer from "../components/ChartRenderer";
import {
  DashboardItem,
  Dashboard,
  ChartDescription,
} from "../components/Utils";
import "./style/lecoindulmnp.scss";

DashboardItem.defaultProps = {
  size: 12,
};

const propertiesTypeQuery = {
  measures: ["Adaptimmos.count"],
  timeDimensions: [
    {
      dimension: "Adaptimmos.insertdate",
      granularity: "day",
    },
  ],
  order: {
    "Adaptimmos.count": "desc",
  },
  dimensions: ["Adaptimmos.type"],
  filters: [
    {
      member: "Adaptimmos.platform",
      operator: "equals",
      values: ["LECOINDULMNP"],
    },
    {
      member: "Adaptimmos.type",
      operator: "equals",
      values: [
        "Résidence Tourisme",
        "Résidence Affaires",
        "Résidence Etudiante",
        "Résidence Ehpad",
        "Résidence Seniors",
      ],
    },
    {
      member: "Adaptimmos.state",
      operator: "equals",
      values: ["Actif"],
    },
  ],
};

const activePropertiesQuery = {
  measures: ["Adaptimmos.count"],
  timeDimensions: [
    {
      dimension: "Adaptimmos.insertdate",
      granularity: "day",
    },
  ],
  order: {
    "Adaptimmos.insertdate": "asc",
  },
  dimensions: [],
  filters: [
    {
      member: "Adaptimmos.platform",
      operator: "equals",
      values: ["LECOINDULMNP"],
    },
    {
      member: "Adaptimmos.state",
      operator: "equals",
      values: ["Actif"],
    },
  ],
};

const newMandatsQuery = {
  measures: ["Adaptimmos.count"],
  timeDimensions: [
    {
      dimension: "Adaptimmos.creationdate",
      granularity: "day",
      dateRange: "This week",
    },
  ],
  order: {
    "Adaptimmos.insertdate": "asc",
  },
  dimensions: [],
  filters: [
    {
      member: "Adaptimmos.platform",
      operator: "equals",
      values: ["LECOINDULMNP"],
    },
  ],
};
const LecoinduLMNPPage = () => {
  const [displaySerie, setDisplaySerie] = useState({});

  const DASHBOARD_SIZE = 12;
  const DASHBOARD_HEIGHT = 380;

  return (
    <Dashboard key={"lecoindulmnp"} rowKey="lecoindulmnp">
      <DashboardItem
        title={[
          "Evolution by type of property",
          ChartDescription(
            "Evolution of the number of properties for each type every day",
            "right"
          ),
        ]}
        size={DASHBOARD_SIZE}
        height={DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: propertiesTypeQuery,
            chartType: "line",
            pivotConfig: {
              x: ["Reports.date.day"],
              y: ["measures"],
              fillMissingDates: true,
              joinDateRange: false,
            },
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Evolution by active mandat",
          ChartDescription(
            "Evolution of the number of active mandats every day",
            "right"
          ),
        ]}
        size={DASHBOARD_SIZE}
        height={DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: activePropertiesQuery,
            chartType: "line",
            pivotConfig: {
              x: ["Reports.date.day"],
              y: ["measures"],
              fillMissingDates: true,
              joinDateRange: false,
            },
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Evolution of new mandats",
          ChartDescription("Number of new mandats every day", "right"),
        ]}
        size={DASHBOARD_SIZE}
        height={DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: newMandatsQuery,
            chartType: "line",
            pivotConfig: {
              x: ["Reports.date.day"],
              y: ["measures"],
              fillMissingDates: true,
              joinDateRange: false,
            },
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
    </Dashboard>
  );
};

export default LecoinduLMNPPage;
