import { useParams } from "react-router-dom";
import "antd/dist/antd.css";
import React from "react";
import { DashboardItem } from "../components/Utils";
import PropertyDashboard from "./PropertyDashboard";

DashboardItem.defaultProps = {
  size: 12,
};

// TODO: When the property has no Leads, display a message instead of an empty chart
/**
 * Display property page
 */
const PropertyPage = () => {
  let { id } = useParams();
  if (!id) {
    return null;
  }

  return <PropertyDashboard id={id} />;
};

export default PropertyPage;
