import "./body.css";
import "antd/dist/antd.css";
import React from "react";
import "@ant-design/compatible";
import LoginPage from "./pages/LoginPage";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import ExplorePage from "./pages/ExplorePage";
import AgentListPage from "./pages/AgentListPage";
import DashboardAdminPage from "./pages/DashboardAdminPage";
import PropertyListPage from "./pages/PropertyListPage";
import PropertyPage from "./pages/PropertyPage";
import AgentPage from "./pages/AgentPage";
import RightmovePage from "./pages/RightmovePage";
import GreenacresPage from "./pages/GreenacresPage";
import BieniciPage from "./pages/BieniciPage";
import SelogerPage from "./pages/SelogerPage";
import SelogerLogicimmoPage from "./pages/SelogerLogicimmoPage";
import ExplorimmoPage from "./pages/ExplorimmoPage";
import SextantWebStatsPage from "./pages/SextantWebStatsPage";
import LogicimmoPage from "./pages/LogicimmoPage";
import ParuVenduPage from "./pages/ParuVenduPage";
import FrancePropertyShopPage from "./pages/FrancePropertyShopPage";
import FrenchPropertyLinksPage from "./pages/FrenchPropertyLinksPage";
import LogoutPage from "./pages/LogoutPage";
import BellesDemeuresPage from "./pages/BellesDemeuresPage";
import LecoinduLMNPPage from "./pages/LecoinduLMNPPage";
import LecoinduLMNPTablePage from "./pages/LecoinduLMNPTablePage";
import AdaptImmoPage from "./pages/AdaptImmoPage";
import RecruitmentPage from "./pages/RecruitmentPage";
import Page404 from "./pages/Page404";
import AuthenticatedRoute from "./authentication/authenticated-route";
import AuthenticatedAppRoutes from "./authentication/authenticated-app-route";
import UnauthorizedPage from "./pages/UnauthorizedPage";

const App = () => {
  const [cubejsApi, setCubejsApi] = React.useState(null);

  return (
    <Router>
      <Switch>
        {/* register page without authentication here */}
        <Route key="login" path="/login" component={LoginPage} />
        <Route key="404" path="/404" component={Page404} />
        <Route
          key="unauthorized"
          path="/unauthorized"
          component={UnauthorizedPage}
        />

        {/* register page for logged user inside here */}
        <AuthenticatedAppRoutes
          cubejsApi={cubejsApi}
          setCubejsApi={setCubejsApi}
        >
          <AuthenticatedRoute
            key="index"
            exact
            path="/"
            component={DashboardAdminPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="admin"
            path="/admin"
            component={DashboardAdminPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="agents"
            path="/list/agent"
            component={AgentListPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="property"
            path="/list/property"
            component={PropertyListPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="lecoindulmnp"
            path="/stats/lecoindulmnp"
            component={LecoinduLMNPPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="lecoindulmnptable"
            path="/stats/lecoindulmnptable"
            component={LecoinduLMNPTablePage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="adaptimmo"
            path="/stats/adaptimmo"
            component={AdaptImmoPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="propertyDashboard"
            path="/dashboard/properties/:id"
            component={PropertyPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="agentDashboard"
            path="/dashboard/agent/:id"
            component={AgentPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="explore"
            path="/explore"
            component={ExplorePage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="rightmove"
            path="/platform/rightmove"
            component={RightmovePage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="greenacres"
            path="/platform/greenacres"
            component={GreenacresPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="bienici"
            path="/platform/bienici"
            component={BieniciPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="seloger"
            path="/platform/seloger"
            component={SelogerPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="selogerlogicimmo"
            path="/platform/selogerlogicimmo"
            component={SelogerLogicimmoPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="logicimmo"
            path="/platform/logicimmo"
            component={LogicimmoPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="paruvendu"
            path="/platform/paruvendu"
            component={ParuVenduPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="bellesdemeures"
            path="/platform/bellesdemeures"
            component={BellesDemeuresPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="explorimmo"
            path="/platform/explorimmo"
            component={ExplorimmoPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="recruitment"
            path="/stats/recruitment"
            component={RecruitmentPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="sextantwebstats"
            path="/platform/sextantwebstats"
            component={SextantWebStatsPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="francepropertyshop"
            path="/platform/francepropertyshop"
            component={FrancePropertyShopPage}
            cubejsApi={cubejsApi}
          />
          <AuthenticatedRoute
            key="frenchpropertylinks"
            path="/platform/frenchpropertylinks"
            component={FrenchPropertyLinksPage}
            cubejsApi={cubejsApi}
          />
          <Route key="logout" path="/logout" component={LogoutPage} />
          <Route key="logged-404" component={Page404} />
          <Route
            key="logged-unauthorized"
            path="/unauthorized"
            component={UnauthorizedPage}
          />
        </AuthenticatedAppRoutes>
      </Switch>
    </Router>
  );
};

export default App;
