import React, { useState } from "react";
import ChartRenderer from "../components/ChartRenderer";
import {
  DashboardItem,
  Dashboard,
  GranularitySelector,
  ChartDescription,
} from "../components/Utils";
import Query from "../query/Query";
import CONSTANT, { getGranularity } from "./Constant";

DashboardItem.defaultProps = {
  size: 12,
};

const phoneCallCountQuery = (granularityFctn) => {
  return {
    measures: ["Reports.phoneCallCount"],
    timeDimensions: [
      {
        dimension: "Reports.date",
        granularity: granularityFctn,
      },
    ],
    order: {
      "Reports.date": "asc",
    },
    filters: [
      {
        member: "Reports.platform",
        operator: "equals",
        values: ["seloger"],
      },
    ],
  };
};

const SelogerPage = () => {
  const [granularity, setGranularity] = useState({});
  const [displaySerie, setDisplaySerie] = useState({});

  return (
    <Dashboard key={"seloger"} rowKey="seloger">
      <DashboardItem
        title={[
          "Daily number of click on a property for the Seloger portal",
          GranularitySelector(setGranularity, 1),
          ChartDescription(
            "Total number of click on the Seloger properties",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.dailyViewCountQuery(
                "seloger",
                getGranularity.bind(null, 1, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Average conversion % for the Seloger platform",
          GranularitySelector(setGranularity, 2),
          ChartDescription(
            "Average in % of conversion for the properties, calculated with the views on the property list divided by the number of click on the ad ",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.conversionQuery(
                "seloger",
                getGranularity.bind(null, 2, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Phone call count by properties for the Seloger platform",
          GranularitySelector(setGranularity, 3),
          ChartDescription(
            "Total number of phone enquiry for the properties published on the Seloger platform",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: phoneCallCountQuery(getGranularity(3, granularity)),
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Email count by properties for the Seloger platform",
          GranularitySelector(setGranularity, 4),
          ChartDescription(
            "Total number of mails enquiry for the properties published on the Seloger platform",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.emailCountQuery(
                "seloger",
                getGranularity.bind(null, 4, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
    </Dashboard>
  );
};

export default SelogerPage;
