import { Result, Button } from "antd";
import { Link } from "react-router-dom";

const UnauthorizedPage = () => {
  return (
    <Result
      status="warning"
      title="There are some problems with your operation. This content is not accessible with your user role..."
      extra={
        <Link to="/stats/lecoindulmnptable">
          <Button type="primary" key="console">
            Back to LMNP section
          </Button>
        </Link>
      }
    />
  );
};

export default UnauthorizedPage;
