import React, { useState } from "react";
import { Dashboard, GranularitySelector } from "../components/Utils";
import KPI from "../components/KPI";
import {
  DashboardItem,
  ChartDescription,
  RepartitionPie,
} from "../components/Utils";
import ChartRenderer from "../components/ChartRenderer";
import { rightmoveDateQuery } from "./PropertyListPage";
import { useCubeQuery } from "@cubejs-client/react";
import {
  DollarCircleOutlined,
  HomeOutlined,
  EyeOutlined,
  MailOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { LeadByCountry } from "./DashboardAdminPage";
import PropertiesMapPage from "./PropertyMapPage";
import CONSTANT, { getGranularity } from "./Constant";
import Query from "../query/Query";

const AvgPriceKPIQuery = () => ({
  measures: ["Performancereports.avgPrice"],
  timeDimensions: [
    {
      dimension: "Performancereports.date",
      granularity: "day",
    },
  ],
  order: {},
  dimensions: [],
  limit: 15,
  filters: [],
});

const CountPublishedPropertiesKPI = () => ({
  measures: ["Performancereports.count"],
  timeDimensions: [],
  order: [["Performancereports.date", "desc"]],
  filters: [],
  dimensions: ["Performancereports.date"],
  limit: 2,
});

const LeadCountKPI = () => ({
  measures: ["Leads.count"],
  timeDimensions: [
    {
      dimension: "Leads.date",
      granularity: "month",
      dateRange: "This quarter",
    },
  ],
  order: {},
  filters: [
    {
      member: "Leads.platform",
      operator: "equals",
      values: ["rightmove"],
    },
  ],
  dimensions: [],
  limit: 3,
});

const ViewCountKPI = () => ({
  measures: ["Performancereports.sumDailyViewsLast30Days"],
  timeDimensions: [
    {
      dimension: "Performancereports.date",
      dateRange: "This quarter",
    },
  ],
  order: {},
  dimensions: [],
  limit: 1100,
  filters: [],
});

const queryNumberAgents = {
  measures: ["Agents.count"],
  order: {},
  dimensions: [],
  filters: [],
};

const queryNumberAgentsRightmove = {
  measures: ["Performancereports.numberAgents"],
  order: {},
  dimensions: [],
  filters: [],
};

const queryRegionsPopulation = {
  measures: ["Performancereports.count"],
  timeDimensions: [
    {
      dimension: "Performancereports.date",
      dateRange: "Today",
    },
  ],
  order: {
    "Performancereports.count": "desc",
  },
  dimensions: ["Performancereports.region"],
  limit: 5000,
};

const RightmovePage = () => {
  const dateMonth = moment()
    .subtract(parseInt(moment().format("DD")) - 1, "day")
    .format("YYYY-MM-DD[T00:00:00.000]");
  const [granularity, setGranularity] = useState({});

  const [date, setDate] = React.useState([]);
  const [responseKPIData, setResponseKPIData] = React.useState({});
  const { resultSet, isLoading } = useCubeQuery([
    rightmoveDateQuery,
    AvgPriceKPIQuery(),
    CountPublishedPropertiesKPI(),
  ]);
  const monthlyKPIResponse = useCubeQuery([LeadCountKPI(), ViewCountKPI()]);
  const requestRightmoveAgent = useCubeQuery(queryNumberAgentsRightmove);
  const requestAllAgent = useCubeQuery(queryNumberAgents);
  const KPI_WIDTH = 6;

  React.useEffect(() => {
    if (!isLoading && resultSet) {
      setDate(resultSet.loadResponses[0].data[0]["Performancereports.date"]);
      setResponseKPIData((previousState) => ({
        ...previousState,
        price: [
          resultSet.loadResponses[1].data[1],
          resultSet.loadResponses[1].data[0],
        ],
        publish: resultSet.loadResponses[2].data[0],
      }));
    }
    if (!monthlyKPIResponse.isLoading && monthlyKPIResponse.resultSet) {
      setResponseKPIData((previousState) => ({
        ...previousState,
        lead: monthlyKPIResponse.resultSet.loadResponses[0].data.filter(
          (o) => o["Leads.date"] === dateMonth
        )?.[0],
        view: monthlyKPIResponse.resultSet.loadResponses[1].data?.[0],
      }));
    }
    if (!requestRightmoveAgent.isLoading && requestRightmoveAgent.resultSet) {
      setResponseKPIData((previousState) => ({
        ...previousState,
        rightmoveAgent:
          requestRightmoveAgent.resultSet.loadResponses[0].data[0][
            "Performancereports.numberAgents"
          ],
      }));
    }
    if (!requestAllAgent.isLoading && requestAllAgent.resultSet) {
      setResponseKPIData((previousState) => ({
        ...previousState,
        agentsTotal:
          requestAllAgent.resultSet.loadResponses[0].data[0]["Agents.count"],
      }));
    }
  }, [
    isLoading,
    resultSet,
    monthlyKPIResponse.isLoading,
    monthlyKPIResponse.resultSet,
    requestRightmoveAgent.isLoading,
    requestRightmoveAgent.resultSet,
    requestAllAgent.isLoading,
    requestAllAgent.resultSet,
  ]);

  const filterByDate = (lastIndexingDate) => {
    return lastIndexingDate.length > 0
      ? [
          {
            member: "Performancereports.date",
            operator: "equals",
            values: [lastIndexingDate],
          },
        ]
      : [];
  };
  return (
    <Dashboard key="dashboard-rightmove">
      <DashboardItem
        title={[
          "Published properties",
          ChartDescription(
            "Number of properties currently online on Rightmove",
            "right"
          ),
        ]}
        size={KPI_WIDTH}
        height={95}
        style={{ float: "left" }}
      >
        <KPI
          icon={<HomeOutlined style={{ fontSize: "40px", color: "#eb2f96" }} />}
          caret={false}
          value={responseKPIData?.publish?.["Performancereports.count"]}
        />
      </DashboardItem>
      <DashboardItem
        title={[
          "Average property price",
          ChartDescription(
            "Average property price of last published properties",
            "right"
          ),
        ]}
        size={KPI_WIDTH}
        height={95}
        style={{ float: "left" }}
      >
        <KPI
          icon={
            <DollarCircleOutlined
              style={{ fontSize: "40px", color: "#52c41a" }}
            />
          }
          value={responseKPIData?.price?.[0]?.["Performancereports.avgPrice"]}
          comparator={
            responseKPIData?.price?.[1]?.["Performancereports.avgPrice"]
          }
          caret={true}
          comparatorText={`Previous avg price`}
        />
      </DashboardItem>
      <DashboardItem
        title={[
          "Count of property leads",
          ChartDescription(
            `Number of property leads received for the current month (${moment(
              dateMonth
            ).format("YYYY-MM")})`,
            "right"
          ),
        ]}
        size={KPI_WIDTH}
        height={95}
        style={{ float: "left" }}
      >
        <KPI
          icon={<MailOutlined style={{ fontSize: "40px", color: "#ffc107" }} />}
          value={responseKPIData?.lead?.["Leads.count"]}
          caret={false}
          comparatorText={`Previous property leads`}
        />
      </DashboardItem>
      <DashboardItem
        title={[
          "Count of property views",
          ChartDescription(
            "Number of property views for the current month",
            "right"
          ),
        ]}
        size={KPI_WIDTH}
        height={95}
        style={{ float: "left" }}
      >
        <KPI
          icon={<EyeOutlined style={{ fontSize: "40px", color: "#1890ff" }} />}
          value={
            responseKPIData?.view?.[
              "Performancereports.sumDailyViewsLast30Days"
            ]
          }
          caret={false}
          comparatorText={`Previous property views`}
        />
      </DashboardItem>
      <PropertiesMapPage
        title="Heatmap of properties count"
        description="HeatMap of the properties distribution in France for each region"
        query={queryRegionsPopulation}
        mapType={"regions.json"}
        type={"Performancereports.region"}
        count={"Performancereports.count"}
        geoType={"nom"}
      />
      <DashboardItem
        size={4}
        title={[
          "Top #15 agents (property published)",
          ChartDescription(
            `List of agents with most properties published. Last indexing at ${moment(
              date,
              "YYYY-MM-DD[T00:00:00.000Z]"
            ).format("YYYY-MM-DD")}`,
            "right"
          ),
        ]}
        size={8}
        height={350}
      >
        {ChartRenderer({
          vizState: {
            query: {
              measures: ["Performancereports.count"],
              timeDimensions: [],
              order: {
                "Performancereports.count": "desc",
              },
              dimensions: ["Performancereports.agentemail"],
              limit: 15,
              filters: filterByDate(date),
            },
            chartType: "table",
            pivotConfig: null,
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Agent's distribution for the Rightmove platform",
          ChartDescription(
            "This chart show's the % of agents that published at least one ad on Rightmove",
            "Right"
          ),
        ]}
        size={12}
        height={240}
      >
        <RepartitionPie
          data={[
            {
              name: "Agents with a rightmove publication",
              value: responseKPIData.rightmoveAgent,
            },
            {
              name: "Agents without any Rightmove publication",
              value:
                responseKPIData.agentsTotal - responseKPIData.rightmoveAgent,
            },
          ]}
        />
      </DashboardItem>
      <DashboardItem
        title={[
          "Lead distribution by country",
          ChartDescription(
            "Lead distribution by country for all email leads",
            "left"
          ),
        ]}
        size={12}
        height={380}
      >
        {ChartRenderer({
          vizState: {
            query: {
              ...LeadByCountry,
              filters: [
                {
                  member: "Leads.platform",
                  operator: "equals",
                  values: ["rightmove"],
                },
              ],
            },
            chartType: "highchartsPie",
            pivotConfig: {},
          },
        })}
      </DashboardItem>
      <DashboardItem title="Views on Rightmove">
        {ChartRenderer({
          vizState: {
            query: {
              measures: ["Performancereports.sumDailyViewsLast30Days"],
              timeDimensions: [],
              order: [["Performancereports.date", "asc"]],
              filters: [],
              dimensions: ["Performancereports.date"],
            },
            chartType: "area",
            pivotConfig: {},
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Count of properties published on Rightmove",
          ChartDescription(
            "Number of properties published on Rightmove portal",
            "right"
          ),
        ]}
      >
        {ChartRenderer({
          averageDisplay: true,
          vizState: {
            query: {
              measures: ["Performancereports.count"],
              timeDimensions: [],
              order: [["Performancereports.date", "asc"]],
              dimensions: ["Performancereports.date"],
              filters: [],
            },
            chartType: "line",
            pivotConfig: {
              x: ["Performancereports.lastUpdated.day"],
              y: ["measures"],
              fillMissingDates: true,
              joinDateRange: false,
            },
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Leads count for the Rightmove portal",
          GranularitySelector(setGranularity, 1),
          ChartDescription(
            "Leads received for the Rightmove portal over time",
            "left"
          ),
        ]}
        direction="left"
        extra={ChartDescription("Lead")}
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          vizState: {
            query: {
              ...Query.leadsForPortal(
                "rightmove",
                getGranularity.bind(null, 1, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
    </Dashboard>
  );
};

export default RightmovePage;
