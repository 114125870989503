import { DatePicker } from "antd";
import { DATE_FILTER_KEY, ALL_DATA_FILTER } from "./DateFilter";

const { RangePicker } = DatePicker;

let isDateRange = (dateRange, defaultValue) => {
  return dateRange.dateFilter !== ALL_DATA_FILTER
    ? dateRange.dateFilter
    : defaultValue
    ? defaultValue
    : null;
};

export const DatePickerSelector = (props) => {
  return (
    <div className="date-picker">
      <RangePicker
        defaultValue={isDateRange(props.dateRange, props.defaultValue)}
        format="YYYY/MM/DD"
        onChange={(moment) =>
          props.setDateRange((previousState) => ({
            ...previousState,
            [DATE_FILTER_KEY]: moment
              ? moment
              : props.defaultValue
              ? props.defaultValue
              : ALL_DATA_FILTER,
          }))
        }
      />
    </div>
  );
};

export default DatePickerSelector;
