import * as d3 from "d3";

const infoDialogPositionLeft = 250;
const infoDialogPositionTop = 775;

// Dialog use to show the departments informations (Name of the department and population)
let infoDialog = d3
  .select("body")
  .append("div")
  .attr("class", "tooltip")
  .style("display", 'none');

export const hideDialog = () => {
  infoDialog
    .html("")
    .style("left", "-500px")
    .style("top", "-500px")
    .style("opacity", 0)
    .style("display", '');
};

export const highlightDepartment = (department) => {
  d3.select(".state" + department.properties.code)
    .transition()
    .duration(100)
    .style("opacity", 0.1);
};

export const resetHighlight = () => {
  d3.selectAll("path")
    .transition()
    .duration(200)
    .style("opacity", 1);
};

export const showDialog = (mapType, numberType, department, numberItem, mouseCoordinate) => {
  infoDialog
    .html(
      `<b>${mapType} : </b>
        ${department.properties.nom}
        <br><b> Number of ${numberType} : </b>
        ${numberItem}`
    )
    .style("left", mouseCoordinate[0] + infoDialogPositionLeft + "px")
    .style("top", mouseCoordinate[1] + infoDialogPositionTop + "px")
    .style("opacity", 0.9)
    .style("display", '');
};
