import React from "react";
import ChartRenderer from "../components/ChartRenderer";
import {
  Dashboard,
  DashboardItem,
  ChartDescription,
} from "../components/Utils";
import AgentMapPage from "./AgentMapPage";
import "./style/agent-list.scss";

const AgentListPage = () => {
  return (
    <div className="agent-list-container">
      <Dashboard key="agent-list">
        {ChartRenderer({
          vizState: {
            query: {
              dimensions: [
                "Agents.email",
                "Agents.name",
                "Agents.phone",
                "Agents.city",
              ],
              timeDimensions: [],
              order: {
                "Agents.email": "asc",
              },
            },
            chartType: "agentsTable",
            pivotConfig: {
              x: ["Agents.email", "Agents.name", "Agents.phone", "Agents.city"],
              y: [],
              fillMissingDates: true,
              joinDateRange: false,
            },
          },
        })}
      </Dashboard>
      <Dashboard>
        <AgentMapPage title="Heatmap of agents count" description="HeatMap of the agents destribution in France for each department" />
        <DashboardItem
          size={8}
          title={[
            "Agent's pack distribution",
            ChartDescription("Agents zoho pack distribution", "right"),
          ]}
        >
          {ChartRenderer({
            vizState: {
              query: {
                dimensions: ["Agents.pack"],
                timeDimensions: [],
                order: {
                  "Agents.count": "desc",
                },
                measures: ["Agents.count"],
              },
              chartType: "pie",
              pivotConfig: null,
            },
          })}
        </DashboardItem>
      </Dashboard>
    </div>
  );
};

export default AgentListPage;
