import React from "react";
import { initCubejsApi } from "../authentication/init-cubejs-api";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { Switch } from "react-router";

const AuthenticatedAppRoutes = (props) => {
  const {
    isLoading,
    error,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();

  // Get CubeJS instance with access_token and set to component state
  const initCubejs = React.useCallback(async () => {
    const accessToken = await getAccessTokenSilently({
      // TODO add audience & scope
      audience: process.env.REACT_APP_CUBEJS_JWT_AUDIENCE,
      iss: process.env.REACT_APP_CUBEJS_JWT_ISSUER,
      scope: process.env.REACT_APP_CUBEJS_JWT_SCOPE,
    });

    props.setCubejsApi(initCubejsApi(accessToken));
  }, [getAccessTokenSilently]);

  // Init CubeJS instance with access_token
  React.useEffect(() => {
    if (!props.cubejsApi && !isLoading && isAuthenticated) {
      initCubejs();
    }
  }, [props.cubejsApi, initCubejs, isAuthenticated, isLoading]);

  // Force to work only for logged in users bye checking isAuthenticated
  React.useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // Redirect not logged users
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, isLoading]);

  if (error) {
    // Redirect not logged users
    return logout({ returnTo: `${window.location.origin}/#/login` });
  }

  if (!props.cubejsApi) {
    return <Loading />;
  }

  return <Switch>{props.children}</Switch>;
};

export default AuthenticatedAppRoutes;
