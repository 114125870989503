import React, { useState } from "react";
import ChartRenderer from "../components/ChartRenderer";
import {
  DashboardItem,
  Dashboard,
  GranularitySelector,
  ChartDescription,
} from "../components/Utils";
import Query from "../query/Query";
import CONSTANT, { getGranularity } from "./Constant";

DashboardItem.defaultProps = {
  size: 12,
};

const platformName = "belles demeures";
const BellesDemeuresPage = () => {
  const [granularity, setGranularity] = useState({});
  const [displaySerie, setDisplaySerie] = useState({});

  return (
    <Dashboard key={platformName} rowKey={platformName}>
      <DashboardItem
        title={[
          "Leads count for the Belles Demeures portal",
          GranularitySelector(setGranularity, 1),
          ChartDescription(
            "Leads received for the Belles Demeures portal over time",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.emailCountQuery(
                platformName,
                getGranularity.bind(null, 1, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Properties published for the Belles Demeures portal",
          GranularitySelector(setGranularity, 2),
          ChartDescription(
            "Number of properties published for the Belles Demeures portal over time",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.reportsForPortal(
                platformName,
                "PROPERTY",
                getGranularity.bind(null, 2, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Number of views for the Belles Demeures portal",
          GranularitySelector(setGranularity, 3),
          ChartDescription(
            "Number of views for the Belles Demeures portal",
            "right"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.dailyViewCountQuery(
                platformName,
                getGranularity.bind(null, 3, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Average conversion % for the Belles Demeures platform",
          GranularitySelector(setGranularity, 4),
          ChartDescription(
            "Average in % of conversion for the properties, calculated with the views on the property list divided by the number of click on the ad ",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.conversionQuery(
                platformName,
                getGranularity.bind(null, 4, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
    </Dashboard>
  );
};

export default BellesDemeuresPage;
