import React, { useState } from "react";
import ChartRenderer from "../components/ChartRenderer";
import {
  DashboardItem,
  Dashboard,
  GranularitySelector,
  ChartDescription,
} from "../components/Utils";
import Query from "../query/Query";
import CONSTANT, { getGranularity } from "./Constant";

DashboardItem.defaultProps = {
  size: 12,
};

const ExplorimmoPage = () => {
  const [granularity, setGranularity] = useState({});
  const [displaySerie, setDisplaySerie] = useState({});

  return (
    <Dashboard key={"explorimmo"} rowKey="explorimmo">
      <DashboardItem
        title={[
          "Daily number of leads received on the Explorimmo platform",
          GranularitySelector(setGranularity, 7),
          ChartDescription(
            "Daily number of leads received for the properties on the Explorimmo platform",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.leadsForPortal(
                "explorimmo",
                getGranularity.bind(null, 7, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Daily number of views for the Figaroimmo portal",
          GranularitySelector(setGranularity, 1),
          ChartDescription(
            "Daily number of views for the properties on the Figaroimmo portal",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.dailyViewCountQuery(
                "figaroimmo",
                getGranularity.bind(null, 1, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Daily number of properties published for the Figaroimmo portal",
          GranularitySelector(setGranularity, 4),
          ChartDescription(
            "Daily number of properties published for the Figaroimmo portal",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.publishedProperties(
                "figaroimmo",
                getGranularity.bind(null, 4, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Daily number of phone enquiries on the Figaroimmo portal",
          GranularitySelector(setGranularity, 2),
          ChartDescription(
            "Daily number of phone enquiries for the properties on the Figaroimmo portal",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.dailyPhoneCallCountQuery(
                "figaroimmo",
                getGranularity.bind(null, 2, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Daily leads received for the Figaroimmo portal",
          GranularitySelector(setGranularity, 3),
          ChartDescription(
            "Daily number of leads received for the properties on the Figaroimmo portal",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.dailyEmailCountQuery(
                "figaroimmo",
                getGranularity.bind(null, 3, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Daily number of views for the Proprieteslefigaro portal",
          GranularitySelector(setGranularity, 4),
          ChartDescription(
            "Daily number of views for the properties on the Proprieteslefigaro portal",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.dailyViewCountQuery(
                "proprieteslefigaro",
                getGranularity.bind(null, 4, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Daily number of properties published for the Proprieteslefigaro portal",
          GranularitySelector(setGranularity, 4),
          ChartDescription(
            "Daily number of properties published for the Proprieteslefigaro portal",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.publishedProperties(
                "proprieteslefigaro",
                getGranularity.bind(null, 4, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Daily number of phone enquiries for Proprieteslefigaro portal",
          GranularitySelector(setGranularity, 5),
          ChartDescription(
            "Daily number of phone enquiries for the properties on the Proprieteslefigaro portal",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.dailyPhoneCallCountQuery(
                "proprieteslefigaro",
                getGranularity.bind(null, 5, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Daily leads received for Proprieteslefigaro portal",
          GranularitySelector(setGranularity, 6),
          ChartDescription(
            "Daily number of leads received for the properties on the Proprieteslefigaro portal",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.dailyEmailCountQuery(
                "proprieteslefigaro",
                getGranularity.bind(null, 6, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
    </Dashboard>
  );
};

export default ExplorimmoPage;
