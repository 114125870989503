import React from "react";
import { geoPath } from "d3-geo";
import * as d3 from "d3";
import "../pages/style/agent-list.scss";

// Size of the map box
const width = "500px";
const height = "500px";
// translate is based on width and height
const translateX = 250;
const translateY = 250;
const projectionScale = 2600;
const strokeColor = "#000000";
const strokeWidth = 0.5;
const projectionLongitude = 2.454071;
const projectionLatitude = 46.279229;

const projection = d3
  .geoConicConformal()
  .center([projectionLongitude, projectionLatitude])
  .scale(projectionScale)
  .translate([translateX, translateY]);

const Map = ({
  geographies,
  mouseoverFunction,
  mouseoutFunction,
  getColor,
}) => {
  return (
    <svg width={width} height={height}>
      <g>
        {geographies.map((department, index) => (
          <path
            key={`path-${index}`}
            d={geoPath().projection(projection)(department)}
            // TODO: Classname is set by department in order to modify the opacity of the department when the cursor is over it
            // Their must be a better way to do that.
            className={"state" + department.properties.code}
            fill={getColor(department)}
            stroke={strokeColor}
            // TODO: Dynamically change the stroke width instead of the opacity when the cursor is over the department
            strokeWidth={strokeWidth}
            onMouseOver={(MouseEvent) =>
              mouseoverFunction(department, MouseEvent)
            }
            onMouseOut={(MouseEvent) =>
              mouseoutFunction(department, MouseEvent)
            }
          />
        ))}
      </g>
    </svg>
  );
};
export default Map;
