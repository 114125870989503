import React from "react";
import { withRouter } from "react-router";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import {
  TeamOutlined,
  HomeOutlined,
  BankOutlined,
  DashboardOutlined,
  LineChartOutlined,
  TableOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import "./style/sider.scss";
import {
  ADMIN_ROLE_KEY,
  hasAdminRole,
} from "../authentication/authenticated-route";
import { useAuth0 } from "@auth0/auth0-react";

export const BASIC_ROLE_HOME_PAGE = "/stats/lecoindulmnptable";
export const ADMIN_ROLE_HOME_PAGE = "/admin";

const { SubMenu } = Menu;

/**
 * Render pages without admin role
 * @param {*} location
 * @returns
 */
const BasicRolePages = ({ location }) => {
  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[
        location.pathname === "/" ? BASIC_ROLE_HOME_PAGE : location.pathname,
      ]}
    >
      <Menu.Item key="/stats/lecoindulmnp" icon={<LineChartOutlined />}>
        <Link to="/stats/lecoindulmnp">LecoinduLMNP</Link>
      </Menu.Item>
      <Menu.Item key="/stats/lecoindulmnptable" icon={<TableOutlined />}>
        <Link to="/stats/lecoindulmnptable">Portefeuille LMNP</Link>
      </Menu.Item>
    </Menu>
  );
};

/**
 * Render section filtered by user role
 * @param {*} user
 * @param {*} location
 * @returns {React.ReactElement}
 */
const renderSectionWithRole = (user, location) => {
  if (hasAdminRole(user)) {
    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[
          location.pathname === "/" ? ADMIN_ROLE_HOME_PAGE : location.pathname,
        ]}
      >
        <Menu.Item key="/admin" icon={<DashboardOutlined />}>
          <Link to="/admin">Admin Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="/list/property" icon={<HomeOutlined />}>
          <Link to="/list/property">Properties list</Link>
        </Menu.Item>
        <Menu.Item key="/list/agent" icon={<TeamOutlined />}>
          <Link to="/list/agent">Agents list</Link>
        </Menu.Item>
        <Menu.Item key="/stats/lecoindulmnp" icon={<LineChartOutlined />}>
          <Link to="/stats/lecoindulmnp">LecoinduLMNP</Link>
        </Menu.Item>
        <Menu.Item key="/stats/lecoindulmnptable" icon={<TableOutlined />}>
          <Link to="/stats/lecoindulmnptable">Portefeuille LMNP</Link>
        </Menu.Item>
        <Menu.Item key="/stats/recruitment" icon={<LineChartOutlined />}>
          <Link to="/stats/recruitment">Recrutement</Link>
        </Menu.Item>
        <Menu.Item key="/stats/adaptimmo" icon={<PieChartOutlined />}>
          <Link to="/stats/adaptimmo">AdaptImmo</Link>
        </Menu.Item>
        <SubMenu icon={<BankOutlined />} key="platforms" title="Platforms">
          <Menu.Item key="/platform/rightmove">
            <Link to="/platform/rightmove">Rightmove</Link>
          </Menu.Item>
          <Menu.Item key="/platform/greenacres">
            <Link to="/platform/greenacres">Greenacres</Link>
          </Menu.Item>
          <Menu.Item key="/platform/bienici">
            <Link to="/platform/bienici">Bien ici</Link>
          </Menu.Item>
          <Menu.Item key="/platform/seloger">
            <Link to="/platform/seloger">Seloger</Link>
          </Menu.Item>
          <Menu.Item key="/platform/selogerlogicimmo">
            <Link to="/platform/selogerlogicimmo">SelogerLogicimmo</Link>
          </Menu.Item>
          <Menu.Item key="/platform/bellesdemeures">
            <Link to="/platform/bellesdemeures">Belles demeures</Link>
          </Menu.Item>
          <Menu.Item key="/platform/explorimmo">
            <Link to="/platform/explorimmo">Explorimmo</Link>
          </Menu.Item>
          <Menu.Item key="/platform/logicimmo">
            <Link to="/platform/logicimmo">Logic-immo</Link>
          </Menu.Item>
          <Menu.Item key="/platform/paruvendu">
            <Link to="/platform/paruvendu">Paru Vendu</Link>
          </Menu.Item>
          <Menu.Item key="/platform/sextantwebstats">
            <Link to="/platform/sextantwebstats">Sextant Web</Link>
          </Menu.Item>
          <Menu.Item key="/platform/francepropertyshop">
            <Link to="/platform/francepropertyshop">France property shop</Link>
          </Menu.Item>
          <Menu.Item key="/platform/frenchpropertylinks">
            <Link to="/platform/frenchpropertylinks">
              French property links
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  }

  return <BasicRolePages location={location} />;
};

const Sider = ({ location }) => {
  const { user } = useAuth0();
  return (
    <Layout.Sider collapsible={true} defaultCollapsed={false} breakpoint={"xl"}>
      {renderSectionWithRole(user[ADMIN_ROLE_KEY], location)}
    </Layout.Sider>
  );
};

export default withRouter(Sider);
