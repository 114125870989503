import {
  DATE_FILTER_KEY,
  ALL_DATA_FILTER,
} from "../components/QueryBuilder/DateFilter";

const CONSTANT = {
  DEFAULT_GRANULARITY: "day",
  DASHBOARD_SIZE: 12,
  DASHBOARD_HEIGHT: 380,
};

export const getGranularity = (id, granularity) => {
  return granularity[id] || CONSTANT.DEFAULT_GRANULARITY;
};

export const getDateRange = (dateRange, defaultValue) => {
  if (
    (dateRange && Object.keys(dateRange).length === 0) ||
    dateRange[DATE_FILTER_KEY] === ALL_DATA_FILTER
  ) {
    return defaultValue ? { dateRange: defaultValue } : {};
  }
  return { dateRange: dateRange[DATE_FILTER_KEY] };
};

export default CONSTANT;
