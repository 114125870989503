import React, { useState, useEffect } from "react";
import {
  DashboardItem,
  Dashboard,
  ChartDescription,
  GranularitySelector,
  RepartitionPie,
} from "../components/Utils";
import { useCubeQuery } from "@cubejs-client/react";
import { LeadByCountry } from "./DashboardAdminPage";
import ChartRenderer from "../components/ChartRenderer";
import CONSTANT, { getGranularity } from "./Constant";
import Query from "../query/Query";

DashboardItem.defaultProps = {
  size: 12,
};

export const getViewCount = (platformName) => ({
  query: {
    measures: ["Reports.viewcount"],
    timeDimensions: [
      {
        dimension: "Reports.date",
        granularity: "day",
      },
    ],
    order: {
      "Reports.date": "asc",
    },
    filters: [
      {
        member: "Reports.reporttype",
        operator: "contains",
        values: ["PROPERTY"],
      },
      {
        member: "Reports.platform",
        operator: "equals",
        values: [platformName],
      },
    ],
    dimensions: [],
  },
  chartType: "area",
  pivotConfig: {},
  dateFormat: "DD. MMM",
});

const queryNumberAgents = {
  measures: ["Agents.count"],
  order: {},
  dimensions: [],
  filters: [],
};

const queryNumberOfPropertyPublishedWithUrlCount = {
  measures: ["Reports.count"],
  timeDimensions: [
    {
      dimension: "Reports.date",
      granularity: "day",
    },
  ],
  order: {
    "Reports.date": "asc",
  },
  filters: [
    {
      member: "Reports.platform",
      operator: "equals",
      values: ["greenacres"],
    },
    {
      member: "Reports.reporttype",
      operator: "equals",
      values: ["PROPERTY"],
    },
    {
      member: "Reports.propertyLink",
      operator: "set",
    },
  ],
};

const queryNumberAgentsGreenacres = {
  measures: ["Reports.agentCount"],
  timeDimensions: [],
  order: {
    "Reports.date": "asc",
  },
  dimensions: [],
  filters: [
    {
      member: "Reports.platform",
      operator: "equals",
      values: ["greenacres"],
    },
    {
      member: "Reports.reporttype",
      operator: "equals",
      values: ["PROPERTY"],
    },
  ],
};

const GreenacresPage = () => {
  const [granularity, setGranularity] = useState({});
  const [agentsGreenacres, setAgentsGreenacres] = useState();
  const [displaySerie, setDisplaySerie] = useState({});
  const [agentsTotal, setAgentsTotal] = useState();
  const requestGreenacresAgent = useCubeQuery(queryNumberAgentsGreenacres);
  const requestAllAgent = useCubeQuery(queryNumberAgents);

  useEffect(() => {
    if (!requestGreenacresAgent.isLoading && requestGreenacresAgent.resultSet) {
      setAgentsGreenacres(
        requestGreenacresAgent.resultSet.loadResponses[0].data[0][
          "Reports.agentCount"
        ]
      );
    }
    if (!requestAllAgent.isLoading && requestAllAgent.resultSet) {
      setAgentsTotal(
        requestAllAgent.resultSet.loadResponses[0].data[0]["Agents.count"]
      );
    }
  }, [
    requestGreenacresAgent.isLoading,
    requestGreenacresAgent.resultSet,
    requestAllAgent.resultSet,
    requestAllAgent.isLoading,
  ]);

  return (
    <Dashboard key={"dashboard-admin"} rowKey="dashboard-admin">
      <DashboardItem
        title={[
          "Leads count for the Greenacres portal",
          GranularitySelector(setGranularity, 1),
          ChartDescription(
            "Leads received for the Greenacres portal over time",
            "left"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.leadsForPortal(
                "greenacres",
                getGranularity.bind(null, 1, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Agent's distribution for the greenacres platform",
          ChartDescription(
            "This chart show's the % of agents that published at least one ad on greenacres",
            "Right"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        <RepartitionPie
          data={[
            {
              name: "Agents with a greenacres publication",
              value: agentsGreenacres,
            },
            {
              name: "Agents without any greenacres publication",
              value: agentsTotal - agentsGreenacres,
            },
          ]}
        />
      </DashboardItem>
      <DashboardItem
        title={[
          "Lead distribution by country",
          ChartDescription(
            "Lead distribution by country for all email leads",
            "left"
          ),
        ]}
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          hideLegend: true,
          vizState: {
            query: {
              ...LeadByCountry,
              filters: [
                {
                  member: "Leads.platform",
                  operator: "equals",
                  values: ["greenacres"],
                },
              ],
            },
            chartType: "highchartsPie",
            pivotConfig: {},
          },
        })}
      </DashboardItem>
      <DashboardItem title="Views on Greenacres">
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: getViewCount("greenacres"),
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Number of properties published by day using urls",
          ChartDescription(
            "Number of properties published on greenacres using the number of report with an url and the property tag",
            "right"
          ),
        ]}
        direction="left"
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: queryNumberOfPropertyPublishedWithUrlCount,
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
    </Dashboard>
  );
};

export default GreenacresPage;
