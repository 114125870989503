import React, { useState, useEffect } from "react";
import { DashboardItem, Dashboard } from "../components/Utils";
import { columns } from "../components/LMNPTableColumns";
import "./style/lecoindulmnp.scss";
import { Table } from "antd";
import Utils from "../utils/utils";

DashboardItem.defaultProps = {
  size: 12,
};

const setList = (columnNames) => {
  let array = [];

  columnNames.map((columnName) => {
    let name = columnName.toLocaleLowerCase().split(" ").join("_");
    array[name] = { filtered: false };
  });
  return array;
};

const LecoinduLMNPPage = () => {
  const columnNames = [
    "Mandat",
    "Etat",
    "Photo",
    "Code postal",
    "Ville",
    "Prix",
    "Environnement",
    "Nom occupant",
    "Type résidence",
    "Taxe fonciere",
    "Charges mensuelles",
    "Loyer annuel",
    "Copropriete charges annuelles",
    "Rentabilité Brute",
    "Loyer triple net",
    "loyer_annuel_triple_net",
    "Rentabilité nette",
    "Date fin bail",
    "Année construction",
    "Surface m²",
  ];

  const [lmnpJson, setLmnpJson] = useState();

  let filteredColumnsList = setList(columnNames);

  useEffect(() => {
    if (!lmnpJson) Utils.getLmnpJson(setLmnpJson);
  }, [lmnpJson]);

  return (
    <div className="lmnp_properties_table">
      <Dashboard key={"lecoindulmnp"} rowKey="lecoindulmnp">
        <Table
          className="lmnp-table"
          bordered
          columns={columns(filteredColumnsList)}
          pagination={{ defaultPageSize: 20, showSizeChanger: false }}
          dataSource={lmnpJson}
          scroll={{ x: 2600, y: 600 }}
          onChange={(pagination, filters) => {
            Object.keys(filters).map((key) => {
              filters[key]
                ? (filteredColumnsList[key] = { filtered: true })
                : (filteredColumnsList[key] = { filtered: false });
            });
          }}
        />
      </Dashboard>
    </div>
  );
};

export default LecoinduLMNPPage;
