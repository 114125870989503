import React from "react";
import { Redirect, Route } from "react-router-dom";
import Header from "../components/Header";
import Sider, {
  ADMIN_ROLE_HOME_PAGE,
  BASIC_ROLE_HOME_PAGE,
} from "../components/Sider";
import { Layout } from "antd";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { CubeProvider } from "@cubejs-client/react";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "../graphql/client";
import UnauthorizedPage from "../pages/UnauthorizedPage";
const ADMIN_ROLE = "admin-user";
export const ADMIN_ROLE_KEY = "https://analytics.sextantproperties.com/roles";

/**
 * Redirect to home page based on role
 * @param {*} role
 * @returns
 */
export const getRoleHomePage = (role) => {
  if (hasAdminRole(role)) {
    return "/";
  }
  return BASIC_ROLE_HOME_PAGE;
};

/**
 * has an LMNP role
 * @param {String[]} roles - the list of roles of
 * @returns {true|false}
 */
export const hasAdminRole = (roles) => roles.includes(ADMIN_ROLE);

/**
 * Display or not component based on role and page location
 * @param {*} user
 * @param {*} routeProps
 * @returns {true|false}
 */
const renderAdminComponents = (user, routeProps) =>
  !hasAdminRole(user[ADMIN_ROLE_KEY]) &&
  !routeProps.location.pathname.startsWith("/stats/lecoindulmnp");

const AppLayout = (props) => {
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Header />
      <Layout>
        <Sider />
        <Layout.Content>{props.children}</Layout.Content>
      </Layout>
    </Layout>
  );
};

const renderRoute = (Component, props, routeProps) => {
  return (
    <CubeProvider cubejsApi={routeProps.cubejsApi}>
      <ApolloProvider client={client}>
        <AppLayout>
          <Component {...props} {...routeProps} />
        </AppLayout>
      </ApolloProvider>
    </CubeProvider>
  );
};

const AuthenticatedRoute = ({ component: Component, ...routeProps }) => {
  const { user } = useAuth0();

  /* redirect to home page corresponding of user role */
  if (renderAdminComponents(user, routeProps)) {
    if (routeProps.location.pathname === ADMIN_ROLE_HOME_PAGE) {
      return <Redirect to={BASIC_ROLE_HOME_PAGE} />;
    }
    /* redirect to unauthorized when admin role missing */
    return <UnauthorizedPage />;
  }

  return (
    <Route
      {...routeProps}
      render={(props) => renderRoute(Component, props, routeProps)}
    />
  );
};

export default withAuthenticationRequired(AuthenticatedRoute, {
  onRedirecting: () => <Loading />,
});
