import { useCubeQuery } from "@cubejs-client/react";
import { RiseOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import KPI from "./KPI";
import Utils from "../utils/utils";

export const mostPopulateRegionQuery = (date) => ({
    measures: ["Adaptimmos.count"],
    timeDimensions: [
      {
        dimension: "Adaptimmos.insertdate",
        dateRange: [date, date],
      },
    ],
    order: [["Adaptimmos.count", "desc"]],
    dimensions: ["Adaptimmos.department"],
    filters: [
      {
        member: "Adaptimmos.platform",
        operator: "equals",
        values: ["ADAPTIMMO"],
      },
    ],
    limit: 1,
  });

const DepartmentKpi = (props) => {
    const [propertiesData, setPropertiesData] = useState({});
    const requestMostPopulateRegion = useCubeQuery(mostPopulateRegionQuery(props.date));

    useEffect(() => {
        if (
          !requestMostPopulateRegion.isLoading &&
          requestMostPopulateRegion.resultSet
        ) {
          setPropertiesData((previousState) => ({
            ...previousState,
            departmentZip:
              requestMostPopulateRegion.resultSet.loadResponses[0].data[0][
                "Adaptimmos.department"
              ],
            departmentCount:
              requestMostPopulateRegion.resultSet.loadResponses[0].data[0][
                "Adaptimmos.count"
              ],
          }));
          Utils.getDepartmentName(propertiesData.departmentZip, setPropertiesData);
        }
      }, [
        requestMostPopulateRegion.isLoading,
        requestMostPopulateRegion.resultSet,
        propertiesData.departmentZip
      ]);

    if(props.date?.length > 0){
        return <KPI
        icon={
          <RiseOutlined style={{ fontSize: "40px", color: "#51CDDB" }} />
        }
        caret={false}
        valueText={`${propertiesData.departmentName}`}
        comparator={propertiesData.departmentCount}
        comparatorText={`Number of properties : `}
      />
    }
    return null;
}

export default DepartmentKpi;