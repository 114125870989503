import React from "react";
import { useCubeQuery } from "@cubejs-client/react";
import { Dashboard } from "../components/Utils";
import { rightmoveDateQuery, reportDateQuery } from "./PropertyListPage";
import moment from "moment";
import Utils from "../utils/utils";
import { DashboardItem, ChartDescription } from "../components/Utils";
import { Radio } from "antd";
import ChartRenderer from "../components/ChartRenderer";
import { useParams } from "react-router-dom";
import CONSTANT from "./Constant";

/**
 * A button to switch granulary
 * @returns {React.Element}
 */
const granularity = () => {
  return (
    <Radio.Group defaultValue="day" style={{ float: "right" }}>
      <Radio.Button value="week">Monthly</Radio.Button>
      <Radio.Button value="week">Weekly</Radio.Button>
      <Radio.Button value="day">Daily</Radio.Button>
    </Radio.Group>
  );
};

/**
 * Render first column, update link name
 */
const renderFirstColumnLink = (columns) => {
  if (columns[0]) {
    columns[0].render = (text) => (
      <a href={`${Utils.getLink(text)}`} rel="noreferrer" target="_blank">
        {Utils.renderPropertyLinkUrl(text)}
      </a>
    );
  }
};

/**
 * Render first column, update link name
 */
const renderLink = (columns) => {
  if (columns[0]) {
    columns[0].render = (text) => (
      <a href={text} rel="noreferrer" target="_blank">
        {text}
      </a>
    );
  }
};

/**
 * Get property link of agent for the specified platform
 * @param {String} agentEmail - agent email
 * @param {String} date - date of indexing
 * @param {String} platformName - plateform Name
 * @returns {CubeJSQueryObject}
 */
const propertyLinks = (agentEmail, date, platformName) => ({
  dimensions: ["Reports.propertyLink"],
  timeDimensions: [],
  order: {
    "Reports.platform": "asc",
  },
  filters: [
    {
      member: "Reports.agentEmail",
      operator: "equals",
      values: [agentEmail],
    },
    {
      member: "Reports.date",
      operator: "equals",
      values: [date],
    },
    {
      member: "Reports.platform",
      operator: "equals",
      values: [platformName],
    },
  ],
});

const AgentPage = () => {
  let { id } = useParams();
  const [date, setDate] = React.useState([]);
  const [reportDate, setReportDate] = React.useState([]);
  const { resultSet, isLoading } = useCubeQuery(rightmoveDateQuery);
  const reportQuery = useCubeQuery(reportDateQuery);

  React.useEffect(() => {
    if (!isLoading && resultSet) {
      setDate(resultSet.loadResponses[0].data[0]["Performancereports.date"]);
    }
    if (!reportQuery.isLoading && reportQuery.resultSet) {
      setReportDate(
        reportQuery.resultSet.loadResponses[0].data[0]["Reports.date"]
      );
    }
  }, [isLoading, resultSet, reportQuery.isLoading, reportQuery.resultSet]);

  if (!id) {
    return null;
  }

  return (
    <Dashboard>
      <DashboardItem
        title={[
          "Properties views by day",
          granularity(),
          ChartDescription(
            "Number of view for all the agent properties",
            "left"
          ),
        ]}
        size={CONSTANT.DASHBOARD_SIZE}
        height={380}
      >
        {ChartRenderer({
          vizState: {
            query: {
              measures: ["Reports.viewcount"],
              timeDimensions: [
                {
                  dimension: "Reports.date",
                  granularity: "day",
                },
              ],
              order: {
                "Reports.date": "asc",
              },
              filters: [
                {
                  member: "Reports.agentEmail",
                  operator: "equals",
                  values: [id],
                },
                {
                  member: "Reports.reporttype",
                  operator: "equals",
                  values: ["AGENT"],
                },
              ],
              limit: 5000,
              dimensions: [],
            },
            chartType: "line",
            pivotConfig: null,
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Leads received by month",
          ChartDescription(
            "Total number of lead received by the agent for the current month",
            "right"
          ),
        ]}
      >
        {ChartRenderer({
          vizState: {
            query: {
              measures: ["Leads.count"],
              timeDimensions: [
                {
                  dimension: "Leads.date",
                  granularity: "month",
                },
              ],
              order: {
                "Leads.date": "asc",
              },
              dimensions: ["Leads.platform"],
              filters: [
                {
                  member: "Leads.agentEmail",
                  operator: "equals",
                  values: [id],
                },
              ],
            },
            chartType: "bar",
            pivotConfig: null,
          },
        })}
      </DashboardItem>
      {/* <DashboardItem title={["Distribution of properties by price", ChartDescription(
        "Price distribution for all the agent properties",
        "right"
      )]}>
        {ChartRenderer({
          vizState: {
            query: {
              "measures": [
                "Performancereports.count"
              ],
              "timeDimensions": [],
              "order": [
                [
                  "Performancereports.count",
                  "desc"
                ],
                [
                  "Performancereports.propertyPrice",
                  "asc"
                ]
              ],
              "filters": [
                {
                  "member": "Performancereports.agentemail",
                  "operator": "equals",
                  "values": [id]
                }
              ],
              "dimensions": [
                "Performancereports.propertyPrice"
              ],
              "limit": 5000
            }, chartType: 'line', pivotConfig: null
          }
        })}
      </DashboardItem> */}
      <DashboardItem
        title={[
          "Rightmove properties links",
          ChartDescription(
            `Rightmove properties of agent ${id}. Last indexing at ${moment(
              date,
              "YYYY-MM-DD[T00:00:00.000Z]"
            ).format("YYYY-MM-DD")}`,
            "right"
          ),
        ]}
      >
        <ChartRenderer
          vizState={{
            query: {
              dimensions: ["Performancereports.rightmoveUrl"],
              timeDimensions: [],
              order: {
                "Performancereports.rightmoveUrl": "asc",
              },
              filters: [
                {
                  member: "Performancereports.agentemail",
                  operator: "equals",
                  values: [id],
                },
                {
                  member: "Performancereports.date",
                  operator: "contains",
                  values: [date],
                },
              ],
            },
            chartType: "table",
            pivotConfig: null,
            columnConfig: renderFirstColumnLink,
            cssClasses: "no-header",
            tableKey: "table-rightmove",
          }}
        />
      </DashboardItem>
      <DashboardItem
        title={[
          "Greenacres properties links",
          ChartDescription(
            `Greenacres properties of agent ${id}. Last indexing at ${moment(
              reportDate,
              "YYYY-MM-DD[T00:00:00.000Z]"
            ).format("YYYY-MM-DD")}`,
            "right"
          ),
        ]}
      >
        <ChartRenderer
          vizState={{
            query: propertyLinks(id, reportDate, "greenacres"),
            chartType: "table",
            pivotConfig: null,
            columnConfig: renderFirstColumnLink,
            cssClasses: "no-header",
            tableKey: "table-greenacres",
          }}
        />
      </DashboardItem>
      <DashboardItem
        title={[
          "France property shop properties links",
          ChartDescription(
            `France property shop properties of agent ${id}. Last indexing at ${moment(
              reportDate,
              "YYYY-MM-DD[T00:00:00.000Z]"
            ).format("YYYY-MM-DD")}`,
            "right"
          ),
        ]}
      >
        <ChartRenderer
          vizState={{
            query: propertyLinks(id, reportDate, "francepropertyshop"),
            chartType: "table",
            pivotConfig: null,
            columnConfig: renderFirstColumnLink,
            cssClasses: "no-header",
            tableKey: "table-francepropertyshop",
          }}
        />
      </DashboardItem>
      <DashboardItem
        title={[
          "Seloger.com properties links",
          ChartDescription(
            `Seloger.com properties of agent ${id}. Last indexing at ${moment(
              "29/06/2021",
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD")}`,
            "right"
          ),
        ]}
      >
        <ChartRenderer
          vizState={{
            query: propertyLinks(
              id,
              moment("29/06/2021", "DD/MM/YYYY").format(
                "YYYY-MM-DD[T00:00:00.000Z]"
              ),
              "seloger"
            ),
            chartType: "table",
            pivotConfig: null,
            columnConfig: renderLink,
            cssClasses: "no-header",
            tableKey: "table-seloger",
          }}
        />
      </DashboardItem>
      {/* TODO FIX SELOGER HARDCODED DATE, latest date for all portals should be retrieve in a single query using aggregate */}
      <DashboardItem
        title={[
          "Bienici properties links",
          ChartDescription(
            `Bienici properties of agent ${id}. Last indexing at ${moment()
              .subtract(1, "day")
              .format("YYYY-MM-DD")}`,
            "right"
          ),
        ]}
      >
        <ChartRenderer
          vizState={{
            query: propertyLinks(
              id,
              moment().subtract(1, "day").format("YYYY-MM-DD[T00:00:00.000Z]"),
              "bienici"
            ),
            chartType: "table",
            pivotConfig: null,
            columnConfig: renderFirstColumnLink,
            cssClasses: "no-header",
            tableKey: "table-bienici",
          }}
        />
      </DashboardItem>
    </Dashboard>
  );
};
export default AgentPage;
