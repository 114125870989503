import React, { useState } from "react";
import ChartRenderer from "../components/ChartRenderer";
import moment from "moment";
import {
  DashboardItem,
  Dashboard,
  ChartDescription,
  RepartitionPie 
} from "../components/Utils";
import KPI from "../components/KPI";
import { useCubeQuery } from "@cubejs-client/react";
import { DollarCircleOutlined, HomeOutlined} from "@ant-design/icons";
import CONSTANT from "./Constant";

DashboardItem.defaultProps = {
  size: 12,
};

const AvgPriceKPIQuery = () => ({
  "measures": [
    "Adaptimmos.avgPrice"
  ],
  "timeDimensions": [],
  "order": [
    [
      "Adaptimmos.avgPrice",
      "desc"
    ],
    [
      "Adaptimmos.insertdate",
      "asc"
    ]
  ],
  "dimensions": [
    "Adaptimmos.insertdate"
  ],
  "filters": [{
    "member": "Adaptimmos.platform",
    "operator": "equals",
    "values": [
      "ADAPTIMMO"
    ]
  }],
  "limit": 2
});

const CountPublishedPropertiesKPI = () => ({
  "measures": [
    "Adaptimmos.count"
  ],
  "timeDimensions": [
    {
      "dimension": "Adaptimmos.insertdate",
      "granularity": "day"
    }
  ],
  "order": [
    [
      "Adaptimmos.insertdate",
      "desc"
    ]],
  "filters": [{
    "member": "Adaptimmos.platform",
    "operator": "equals",
    "values": [
      "ADAPTIMMO"
    ]
  }],
  "limit": 1
});

const queryNumberAgents = () => ({
  "measures": [
    "Agents.count"
  ],
  "order": {},
  "dimensions": [],
  "filters": []
})

const queryNumberAgentsAdaptimmos = () => ({
  "measures": [
    "Adaptimmos.numberAgents"
  ],
  "timeDimensions": [],
  "order": {},
  "filters": [{
    "member": "Adaptimmos.platform",
    "operator": "equals",
    "values": [
      "ADAPTIMMO"
    ]
  }],
});


const newMandatsQuery = {
  "measures": [
    "AdaptImmosMandats.count"
  ],
  "timeDimensions": [
    {
      "dimension": "AdaptImmosMandats.creationdate",
      "dateRange": "This month",
      "granularity": "day"
    }
  ],
  "order": {
    "AdaptImmosMandats.creationdate": "asc"
  },
  "filters": [
    {
      "member": "AdaptImmosMandats.platform",
      "operator": "equals",
      "values": [
        "ADAPTIMMO"
      ]
    }
  ],
  "dimensions": []
}

const agentsNumberOfPropertiesQuery = {
  measures: [
    "Adaptimmos.count"
  ],
  timeDimensions: [{
    "dimension": "Adaptimmos.insertdate",
    "dateRange": "Today"
  }],
  order: {
    "Adaptimmos.count": "desc"
  },
  dimensions: [
    "Adaptimmos.agentemail"
  ],
  filters: [
    {
      member: "Adaptimmos.platform",
      operator: "equals",
      values: [
        "ADAPTIMMO"
      ]
    }
  ],
  limit: 15
};

const mandatStateQuery = {
  measures: [
    "Adaptimmos.count"
  ],
  timeDimensions: [
    {
      "dimension": "Adaptimmos.insertdate",
      "dateRange": "Today"
    }
  ],
  order: {
    "Adaptimmos.count": "desc"
  },
  dimensions: [
    "Adaptimmos.state"
  ],
  filters: [
    {
      member: "Adaptimmos.platform",
      operator: "equals",
      values: [
        "ADAPTIMMO"
      ]
    }
  ],
  limit: 5000
}

const priceRange = (date) => ({
  measures : [
    "Adaptimmos.countPrice2",
    "Adaptimmos.countPrice3",
    "Adaptimmos.countPrice1",
    "Adaptimmos.countPrice4",
    "Adaptimmos.countPrice5",
    "Adaptimmos.countPrice6",
    "Adaptimmos.countPrice7",
    "Adaptimmos.countPrice8"
  ],
  timeDimensions : [],
  order : {},
  dimensions : [
    "Adaptimmos.insertdate"
  ],
  filters : [
    {
      "member": "Adaptimmos.insertdate",
      "operator": "equals",
      "values": [
        date
      ]
    }
  ]
});

const AdaptImmoPage = () => {
  const [displaySerie, setDisplaySerie] = useState({});
  const [responseKPIData, setResponseKPIData] = React.useState({});
  const { resultSet, isLoading } = useCubeQuery([AvgPriceKPIQuery(), CountPublishedPropertiesKPI()]);
  const requestAdaptimmosAgent = useCubeQuery(queryNumberAgentsAdaptimmos());
  const requestAllAgent = useCubeQuery(queryNumberAgents());
  const KPI_WIDTH = 6;
  const yesterdayDate = moment().subtract(1, 'day').format("YYYY-MM-DD");

  React.useEffect(() => {
    if (!isLoading && resultSet) {
      setResponseKPIData(previousState => ({...previousState, price: [resultSet.loadResponses[0].data[0], resultSet.loadResponses[0].data[1]],  publish: resultSet.loadResponses[1].data[0]}));
    }
    if (!requestAdaptimmosAgent.isLoading && requestAdaptimmosAgent.resultSet) {
      setResponseKPIData(previousState => ({...previousState, adaptimmoAgent : requestAdaptimmosAgent.resultSet.loadResponses[0].data[0]["Adaptimmos.numberAgents"]}));
    }
    if (!requestAllAgent.isLoading && requestAllAgent.resultSet) {
      setResponseKPIData(previousState => ({...previousState, agentsTotal: requestAllAgent.resultSet.loadResponses[0].data[0]["Agents.count"]}));
    }

  }, [isLoading, resultSet, 
    requestAdaptimmosAgent.isLoading, requestAdaptimmosAgent.resultSet,
    requestAllAgent.isLoading, requestAllAgent.resultSet]);

  return (
    <Dashboard key={"adaptimmo"} rowKey="adaptimmo">
      <DashboardItem
        title={[
          "Agent's with a published property",
          ChartDescription(
            "This chart show's the % of agents that published at least one property on SEXTANT FRANCE (LMNP properties removed).",
            "Right"
          ),
        ]}
        size={12}
        height={240}
      >
        <RepartitionPie data={[{name:"Agents with a published property", value: responseKPIData.adaptimmoAgent},{name:"Agents without any published property", value:responseKPIData.agentsTotal - responseKPIData.adaptimmoAgent}]}/>
      </DashboardItem>
      <DashboardItem title={["Published properties", ChartDescription("Number of properties currently published by SEXTANT FRANCE (LMNP properties removed).", "right")]} size={KPI_WIDTH} height={95} style={{ float: "left" }}>
        <KPI icon={<HomeOutlined style={{fontSize: "40px", color: "#eb2f96"}} />}
             caret={false}
             value={responseKPIData?.publish?.["Adaptimmos.count"]}  />
      </DashboardItem>
      <DashboardItem title={["Average property price", ChartDescription("Average property price of last published properties on SEXTANT FRANCE (LMNP properties removed).", "right")]} size={KPI_WIDTH} height={95} style={{ float: "left" }}>
        <KPI icon={<DollarCircleOutlined style={{fontSize: "40px", color: "#52c41a"}} />} 
                value={responseKPIData?.price?.[0]?.["Adaptimmos.avgPrice"]}
                comparator={responseKPIData?.price?.[1]?.["Adaptimmos.avgPrice"]}
                caret={true}
                comparatorText={`Previous avg price`}  />
      </DashboardItem>
      <DashboardItem
        title={[
          "Top #15 agents (property published)",
          ChartDescription(
            "The number of properties own by each agents",
            "right"
          ),
        ]}
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: agentsNumberOfPropertiesQuery,
            chartType: "table",
            pivotConfig: {
              x: ["Reports.date.day"],
              y: ["measures"],
              fillMissingDates: true,
              joinDateRange: false,
            },
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Distribution of mandats by state",
          ChartDescription(
            "Distribution of mandats",
            "right"
          ),
        ]}
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: mandatStateQuery,
            chartType: "pie",
            pivotConfig: {
              x: ["Reports.date.day"],
              y: ["measures"],
              fillMissingDates: true,
              joinDateRange: false,
            },
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "New properties published by day",
          ChartDescription(
            `New properties published by day on the ftp agence feed for the current month, 
            this chart use the adaptimmosmandats schema in order to only query the new mandats and not the updated ones`,
            "right"
          ),
        ]}
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: newMandatsQuery,
            useTitle: true,
            chartType: "bar",
            pivotConfig: {
              x: ["AdaptImmosMandats.creationdate.day"],
              y: ["measures"],
              fillMissingDates: true,
              joinDateRange: false,
            },
            dateFormat: "DD. MMM"
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Count of properties by range of prices",
          ChartDescription(
            `Count of properties by range of prices for the adaptimmo feed (indexation time: ${yesterdayDate})`,
            "right"
          ),
        ]}
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}>
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: priceRange(yesterdayDate),
            chartType: "bar",
            pivotConfig: {},
            dateFormat: "DD. MMM",
            useTitle: true
          }
        })}
      </DashboardItem>
    </Dashboard>
  );
};

export default AdaptImmoPage;
