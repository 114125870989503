import React, { useState, useEffect } from "react";
import Map from "../components/Map";
import * as d3 from "d3";
import { useCubeQuery } from "@cubejs-client/react";
import { DashboardItem } from "../components/Utils";
import * as MapUtils from "./MapUtils";
import {ChartDescription} from "../components/Utils"

const whiteHex = "#FFFFFF";

const quantile = d3
  .scaleQuantile()
  .domain([0, 154])
  // TODO: change by a d3 range, and change opacity instead of color
  .range([
    "#E2E6BD",
    "#F1DE81",
    "#F6C971",
    "#EEAB65",
    "#DA8459",
    "#B9534C",
    "#8E133B",
  ]);

const PropertiesMapPage = (props) => {
  const [geographies, setGeographies] = useState([]);
  const [properties, setProperties] = useState([]);
  const { resultSet, isLoading } = useCubeQuery(props.query);

  const mouseoverFunction = (geoElement, mouseEvent) => {
    let mouseCoordinate = d3.pointer(mouseEvent);
    let numberProperties = 0;

    if (properties[geoElement.properties[props.geoType]] !== undefined) {
      numberProperties = properties[geoElement.properties[props.geoType]].nbProperties;
    }
    MapUtils.highlightDepartment(geoElement);
    MapUtils.showDialog("Region", "properties", geoElement, numberProperties, mouseCoordinate);
  };

  const mouseoutFunction = () => {
    MapUtils.resetHighlight();
    MapUtils.hideDialog();
  };

  const getColor = (geoElement) => {
    if (properties[geoElement.properties[props.geoType]] !== undefined) {
      return quantile(properties[geoElement.properties[props.geoType]].nbProperties);
    } else {
      return whiteHex;
    }
  };

  const getProperties = () => {
    var propertiesNumberByDepObj = {};
    resultSet.loadResponses[0].data.forEach((element) => {
      propertiesNumberByDepObj[element[props.type]] = {
        nbProperties: element[props.count],
      };
    });
    setProperties(propertiesNumberByDepObj);
  };

  useEffect(() => {
    if (!isLoading && resultSet) {
      getProperties();
      d3.json(props.mapType).then((geoElements) => {
        setGeographies(geoElements.features);
      });
    }
  }, [isLoading, resultSet]);

  return (
      <DashboardItem title={[props.title, ChartDescription(props.description, "right")]} size={12} height={500}>
        <Map
          geographies={geographies}
          mouseoverFunction={mouseoverFunction}
          mouseoutFunction={mouseoutFunction}
          getColor={getColor}
        />
      </DashboardItem>
  );
};

export default PropertiesMapPage;
