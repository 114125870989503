/**
 * Following example from cubejs
 * https://github.com/cube-js/cube.js/blob/master/examples/auth0/dashboard-app
 */
import cubejs from "@cubejs-client/core";
import Utils from "../utils/utils";

const CUBEJS_BACKEND_URL = Utils.isDev
  ? process.env.REACT_APP_CUBEJS_BACKEND_URL
  : process.env.REACT_APP_CUBEJS_BACKEND_URL_PROD;

export const initCubejsApi = (accessToken) => {
  return cubejs({
    apiUrl: `${CUBEJS_BACKEND_URL}/cubejs-api/v1`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
