import {
  Input,
  Button,
  Space,
  Row,
  Col,
  Card,
  Radio,
  Tooltip,
  Slider,
} from "antd";
import moment from "moment-timezone";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip as RechartsTooltip,
} from "recharts";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

/**
 * Dashboard component
 * @param {Object} props
 * @returns {ReactElement} - a React component to display dashboard
 */
export const Dashboard = (props) => {
  return [
    <Row
      key={`${props.rowKey}-1`}
      type="flex"
      align="top"
      gutter={24}
      style={{
        marginBottom: 20,
        marginRight: 0,
      }}
    >
      <Col span={24} lg={12} align="left"></Col>
    </Row>,
    <Row
      key={`${props.rowKey}-2`}
      type="flex"
      align="top"
      gutter={24}
      style={{
        marginRight: 0,
        marginLeft: 0,
      }}
    >
      {props.children}
    </Row>,
  ];
};

/**
 * Dashboard Item component
 */
export const DashboardItem = (props) => {
  return (
    <Col key={`col-${props.title?.[0]}`} span={24} lg={props.size}>
      <Card
        key={`card-${props.title?.[0]}`}
        title={props.title}
        style={{
          marginBottom: "24px",
          borderRadius: "7px",
        }}
      >
        <div
          key={`card-div-${props.title?.[0]}`}
          className="dashboard-item-content"
          style={{
            height: props.height,
            width: props.width,
            overflowX: props.scrollX ? "scroll" : null,
          }}
        >
          {props.children}
        </div>
      </Card>
    </Col>
  );
};

export const CheckIfLeadsInDatesArray = (leadsArr, data) => {
  let d = leadsArr.map((lead) => {
    const d = data.find((d) => d["Reports.date"] === lead?.["Leads.date.day"]);
    return d;
  });
  if (!d.every((val, i, arr) => val === arr[0])) {
    return true;
  }
  return false;
};

export const CheckIfPriceChangeInArray = (priceArr) => {
  const defaultPrice = priceArr?.[0]?.["Adaptimmos.price"] || 0;
  const changes =
    priceArr?.reduce(
      (acc, currentValue) => {
        if (
          parseInt(currentValue?.["Adaptimmos.price"]) < acc[acc.length - 1]
        ) {
          return [...acc, currentValue];
        }
        return [...acc];
      },
      [defaultPrice]
    ) || [];
  if (changes.length > 1) {
    return true;
  }
  return false;
};

export const AreaChartLegend = (leadsArr, data, priceArr) => {
  let legends = [];

  if (CheckIfLeadsInDatesArray(leadsArr, data)) {
    legends.push({ value: "Lead count", type: "square", color: "#FF685E" });
  }
  if (CheckIfPriceChangeInArray(priceArr)) {
    legends.push({
      value: "Price reduction",
      type: "square",
      color: "#188FA0",
    });
  }
  if (legends.length > 0) {
    return (
      <foreignObject width="100%" height="100%">
        <Legend
          payload={legends}
          width="100%"
          margin={{ top: 10 }}
          wrapperStyle={{ position: "relative" }}
        />
      </foreignObject>
    );
  }
};

/**
 * GranularitySelector Component
 * @param {React.setStateAction} setGranularityFnc
 * @param {Integer} id - the chart ID
 * @returns {ReactElement} - a React component to choose granularity of chart
 */
export const GranularitySelector = (setGranularityFnc, id) => {
  return (
    <Radio.Group
      key={`radio-${id}`}
      onChange={(e) =>
        setGranularityFnc((previousState) => ({
          ...previousState,
          [id]: e.target.value,
        }))
      }
      style={{ float: "right" }}
      defaultValue="day"
    >
      <Radio.Button value="month">Monthly</Radio.Button>
      <Radio.Button value="week">Weekly</Radio.Button>
      <Radio.Button value="day">Daily</Radio.Button>
    </Radio.Group>
  );
};

export const ChartDescription = (description, direction) => {
  return (
    <div
      key={`tooltip-div-${description}`}
      className="tooltip-container"
      style={{
        paddingRight: "10px",
        float: direction,
      }}
    >
      <Tooltip key={`tooltip-${description}`} title={description}>
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );
};

export const RepartitionPie = (props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={[props.data[0], props.data[1]]}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={90}
          dataKey="value"
        >
          <Cell key={`cell-1`} fill={"#7A77FF"} />
          <Cell key={`cell-2`} fill={"#cfcfcf"} />
        </Pie>
        <Legend />
        <RechartsTooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

/**
 * Range filter on columns
 * @param {String} dataIndex - Data index
 * @param {Number} min - Minimum value of the slider
 * @param {Number} max - Maximum value of the slider
 * @param {String} filterTitle - Title of the slider
 * @param {Object} marks - Number and style of the marks
 */
export const getColumnRangeProps = (
  dataIndex,
  min,
  max,
  filterTitle,
  marks
) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
    <div style={{ padding: 30 }}>
      <p style={{ textAlign: "center" }}>
        <b>{filterTitle}</b>
      </p>
      <Slider
        marks={marks}
        range
        min={min}
        max={max}
        defaultValue={[min, max]}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e ? [e] : []);
        }}
        onAfterChange={(e) => {
          confirm();
        }}
        style={{ width: 250, marginBottom: 8, display: "block" }}
      />
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex] >= value[0] && record[dataIndex] <= value[1],
  render: (text) => text,
});

/**
 * Search filter on columns
 * @param {String} dataIndex - Data index
 * @param {Boolean} isDate - Is the dataIndex a date or no
 * @param {Boolean} includeSearch - If we want and include Search or no
 * @param {Array<Object>} filteredColumnsList - List of columns object
 */
export const getColumnSearchProps = (
  dataIndex,
  isDate,
  includeSearch,
  filteredColumnsList
) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setSelectedKeys([]);
            confirm();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => {
    return (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    );
  },
  onFilter: (value, record) => {
    if (includeSearch) {
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "";
    }
    return record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .startsWith(value.toLowerCase())
      : "";
  },
  render: (text, record) => {
    return {
      props: {
        style: {
          background: filteredColumnsList[dataIndex].filtered ? "#80c1ff" : "",
        },
      },
      children: isDate ? (
        moment(text).format("YYYY-MM-DD")
      ) : dataIndex === "mandat" ? (
        <a
          href={`${record["Titre + Lien Web"]}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      ) : (
        text
      ),
    };
  },
});
