import React, { useState } from "react";
import { Dashboard } from "../components/Utils";
import {
  DashboardItem,
  GranularitySelector,
  ChartDescription,
} from "../components/Utils";
import { EditableTable } from "../components/RecruitmentTable";
import ChartRenderer from "../components/ChartRenderer";
import { useCubeQuery } from "@cubejs-client/react";
import DataChecker from "../components/DataChecker";
import moment from "moment";
import "./style/recrutement.scss";
import { getDateRange, getGranularity } from "./Constant";
import {
  DATE_FILTER_KEY,
  PERIOD_FILTER_NAME,
  ALL_DATA_FILTER,
} from "../components/QueryBuilder/DateFilter";

const LEBONCOIN_PRICE = 2750;
const FACEBOOK_PRICE = 3000;
const INDEED_PRICE = 3000;
const STEPSTONE_PRICE = 2800;
const GOOGLE_PRICE = 7120;
const TALENT_PRICE = 3000;
const KELJOB_PRICE = 250;
const TALENT_PLUG_PRICE = 250;
const TROVIT_PRICE = 0;
const JOBCAST_PRICE = 0;
const lastMonthDate = moment()
  .subtract(1, "months")
  .endOf("month")
  .format("YYYY-MM-DD");
const TwoMonthAgoDate = moment()
  .subtract(2, "months")
  .startOf("month")
  .format("YYYY-MM-DD");

const leadTypeDistribution = {
  measures: ["Recruitmentleads.count"],
  timeDimensions: [],
  order: {
    "Recruitmentleads.count": "desc",
  },
  dimensions: ["Recruitmentleads.type"],
};

const numberOfLeadsForTheLastMonthQuery = {
  measures: ["Recruitmentleads.count"],
  timeDimensions: [
    {
      dimension: "Recruitmentleads.date",
      granularity: "month",
      dateRange: [TwoMonthAgoDate, lastMonthDate],
    },
  ],
  order: {
    "Recruitmentleads.count": "desc",
  },
  dimensions: ["Recruitmentleads.type"],
};

let platformPrice = {};

const RecruitmentPage = () => {
  const [granularity, setGranularity] = useState({});
  const [dateRange, setDateRange] = useState({
    [DATE_FILTER_KEY]: ALL_DATA_FILTER,
  });
  const [displaySerie, setDisplaySerie] = useState({});
  const [leads, setLeads] = useState([]);
  const { resultSet, isLoading } = useCubeQuery(
    numberOfLeadsForTheLastMonthQuery
  );

  platformPrice["Le Bon Coin"] = LEBONCOIN_PRICE;
  platformPrice["Facebook"] = FACEBOOK_PRICE;
  platformPrice["Indeed"] = INDEED_PRICE;
  platformPrice["Stepstone"] = STEPSTONE_PRICE;
  platformPrice["Google"] = GOOGLE_PRICE;
  platformPrice["Talent"] = TALENT_PRICE;
  platformPrice["Keljob"] = KELJOB_PRICE;
  platformPrice["Talent plug"] = TALENT_PLUG_PRICE;
  platformPrice["Trovit"] = TROVIT_PRICE;
  platformPrice["Jobcast"] = JOBCAST_PRICE;

  React.useEffect(() => {
    if (!isLoading && resultSet) {
      const updatedResultSet = formatLeadsData(resultSet.loadResponses[0].data);
      setLeads(updatedResultSet);
    }
  }, [isLoading, resultSet]);

  const formatLeadsData = (data) => {
    let filteredData = {};
    data.forEach((row) => {
      if (
        row["Recruitmentleads.date"] ===
        moment(TwoMonthAgoDate, "YYYY-MM-DD").format(
          "YYYY-MM-DD[T00:00:00.000]"
        )
      ) {
        filteredData = {
          ...filteredData,
          [row["Recruitmentleads.type"]]: {
            ...filteredData[row["Recruitmentleads.type"]],
            source: row["Recruitmentleads.type"],
            cost: platformPrice[row["Recruitmentleads.type"]],
            count: row["Recruitmentleads.count"],
            cpa: Math.round(
              platformPrice[row["Recruitmentleads.type"]] /
                row["Recruitmentleads.count"]
            ),
          },
        };
      } else {
        filteredData = {
          ...filteredData,
          [row["Recruitmentleads.type"]]: {
            ...filteredData[row["Recruitmentleads.type"]],
            source: row["Recruitmentleads.type"],
            cost2: platformPrice[row["Recruitmentleads.type"]],
            count2: row["Recruitmentleads.count"],
            cpa2: Math.round(
              platformPrice[row["Recruitmentleads.type"]] /
                row["Recruitmentleads.count"]
            ),
          },
        };
      }
    });
    /* remove cost equals to 0 */
    return Object.values(filteredData).filter((row) => row.cost);
  };

  return [
    <Dashboard key="dashboard-rightmove">
      <DashboardItem
        title={[
          "Leads recrutement",
          GranularitySelector(setGranularity, 1),
          ChartDescription("Leads recrutement (source: Googlesheet)", "left"),
        ]}
        size={24}
        height={380}
        style={{ float: "left" }}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          setDateRange,
          dateRange,
          dateFilterType: PERIOD_FILTER_NAME,
          vizState: {
            query: {
              measures: ["Recruitmentleads.count"],
              timeDimensions: [
                {
                  dimension: "Recruitmentleads.date",
                  granularity: getGranularity(1, granularity),
                  ...getDateRange(dateRange),
                },
              ],
              order: {
                "Recruitmentleads.date": "asc",
              },
              dimensions: ["Recruitmentleads.type"],
            },
            chartType: "bar",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Distribution of leads by sources",
          ChartDescription("Distribution of leads by souces", "right"),
        ]}
        size={16}
        height={380}
        style={{ float: "left" }}
      >
        {ChartRenderer({
          vizState: {
            query: leadTypeDistribution,
            chartType: "highchartsPie",
            pivotConfig: null,
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Leads cost by platform",
          ChartDescription(
            "Average cost per ad (CPA) for the last month and two month ago",
            "right"
          ),
        ]}
        size={24}
        height={460}
        style={{ float: "right" }}
      >
        <DataChecker data={leads}>
          <EditableTable data={leads}></EditableTable>
        </DataChecker>
      </DashboardItem>
    </Dashboard>,
  ];
};

export default RecruitmentPage;
