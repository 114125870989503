import moment from "moment";
import axios from "axios";

const isDev =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";
const BACKEND_URL = isDev
  ? process.env.REACT_APP_SERVER_URL
  : process.env.REACT_APP_SERVER_URL_PROD;

export const Utils = {
  isDev,
  isProduction: process.env.NODE_ENV === "production",
  /**
   * @param {date|moment} start The start date
   * @param {date|moment} end The end date
   * @param {string} type The range type. eg: 'days', 'hours' etc
   * @param {String} format The date format
   * @returns {Array<String> | null}
   */
  getRange: (startDate, endDate, type, format) => {
    if (type) {
      console.error("Getrange type undefined");
      return null;
    }
    let fromDate = moment(startDate, format);
    let toDate = moment(endDate, format);
    let diff = toDate.diff(fromDate, type);
    let range = [];
    for (let i = 0; i < diff; i++) {
      const newDate = moment(startDate, format).add(i, type);
      range.push(newDate.format(format));
    }
    return range;
  },
  getDepartmentName: (zipcode, setPropertiesData) => {
    return axios
      .get(`${BACKEND_URL}/api/zip/${zipcode}`)
      .then((response) => {
        if (response.status === 200) {
          setPropertiesData((previousState) => ({
            ...previousState,
            departmentName: response.data,
          }));
        } else {
          console.error(`Something went wrong ${response.status}`);
        }
      })
      .catch((e) => {
        console.error(`Something went wrong ... ${e}`);
      });
  },
  getLmnpJson: (setLmnpJson) => {
    return axios
      .get(`${BACKEND_URL}/api/lmnp/json`)
      .then((response) => {
        if (response.status === 200) {
          setLmnpJson(response.data);
        } else {
          console.error(`Something went wrong ${response.status}`);
        }
      })
      .catch((e) => {
        console.error(`Something went wrong ... ${e}`);
      });
  },
  getLink: (link) => {
    if (!link) {
      return null;
    }
    return link
      ? link?.indexOf("https://") === -1
        ? "https://" + link
        : link
      : null;
  },

  setLink: (link) => {
    if (!link) {
      return null;
    }
    let startAdressIndex =
      link.indexOf("www.") === -1
        ? link.indexOf("https://") + 8
        : link.indexOf("www.") + 4;
    let endAdressIndex = link.indexOf(".", startAdressIndex);
    return link.substring(startAdressIndex, endAdressIndex);
  },
  getDateDiff: (endDate, startDate, measurementUnit) => {
    return moment(endDate).diff(moment(startDate), measurementUnit);
  },
  renderPropertyLinkUrl: (link) => {
    const path = new URL(
      `${link.indexOf("https://") > -1 ? "" : "https://"}${link}`
    ).pathname;
    const paths = path.split("/");
    return paths[paths.length - 1];
  },
};

export default Utils;
