import { Button } from "antd";
import { ImportOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import "./style/logout.scss";

const LogoutPage = () => {
  const { logout } = useAuth0();
  return (
    <div>
      <h1>
        <img
          alt="LogoSextant"
          className="sextant-logo"
          src={`${window.location.origin}/logosextant.png`}
        />
        <span style={{ marginLeft: "10px" }}>Sextant Analytics</span>
      </h1>

      <div class="card">
        <div class="container">
          <ImportOutlined className="logout-icon" />
          <h3>
            <b>Are you sure you want to log out ?</b>
          </h3>
          <div class="buttons">
            <Link to="/admin">
              <Button className="button" type="primary" shape="round">
                No, keep me in !
              </Button>
            </Link>

            <Button
              className="button"
              shape="round"
              onClick={() =>
                logout({ returnTo: `${window.location.origin}/#/login` })
              }
            >
              Yes, log me out
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutPage;
