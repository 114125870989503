const getTitle = (n) => `Display last ${n === 1 ? "month" : `${n} months`}`;
export const DATE_FILTER_KEY = "dateFilter";
export const PICKER_FILTER_NAME = "picker";
export const PERIOD_FILTER_NAME = "period";
export const ALL_DATA_FILTER = "All";

export const DateFilterSelector = (props) => {
  return (
    <div className="date-filter">
      <div
        onClick={(e) =>
          props.setDateRange((previousState) => ({
            ...previousState,
            [DATE_FILTER_KEY]: "from 1 months ago to now",
          }))
        }
        className={`date-filter-item ${
          props.dateRange[DATE_FILTER_KEY] === "from 1 months ago to now"
            ? "active"
            : ""
        }`}
        title={getTitle(1)}
        itemName={"1m"}
      >
        1m
      </div>
      <div
        onClick={(e) =>
          props.setDateRange((previousState) => ({
            ...previousState,
            [DATE_FILTER_KEY]: "from 3 months ago to now",
          }))
        }
        className={`date-filter-item ${
          props.dateRange[DATE_FILTER_KEY] === "from 3 months ago to now"
            ? "active"
            : ""
        }`}
        title={getTitle(3)}
        itemName={"3m"}
      >
        3m
      </div>
      <div
        onClick={(e) =>
          props.setDateRange((previousState) => ({
            ...previousState,
            [DATE_FILTER_KEY]: "from 6 months ago to now",
          }))
        }
        className={`date-filter-item ${
          props.dateRange[DATE_FILTER_KEY] === "from 6 months ago to now"
            ? "active"
            : ""
        }`}
        title={getTitle(6)}
        itemName={"6m"}
      >
        6m
      </div>
      <div
        onClick={(e) =>
          props.setDateRange((previousState) => ({
            ...previousState,
            [DATE_FILTER_KEY]: ALL_DATA_FILTER,
          }))
        }
        className={`date-filter-item ${
          props.dateRange[DATE_FILTER_KEY] === ALL_DATA_FILTER ? "active" : ""
        }`}
        title="Display full period"
        itemName={ALL_DATA_FILTER}
      >
        All
      </div>
    </div>
  );
};

export default DateFilterSelector;
