import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import "./style/kpi.scss";

const KPI = (props) => {
  const renderCaret = (a, b) => {
    if (!isNaN(a) && !isNaN(b)) {
      return a <= b ? (
        <CaretUpOutlined style={{ color: "green" }} />
      ) : (
        <CaretDownOutlined style={{ color: "red" }} />
      );
    }
  };

  const renderKPI = () => {
    return (
      <div style={{ display: "flex" }}>
        <div>{props.icon}</div>
        {props.value || props.value === 0 ? (
          <div className="kpi-value">{Math.round(props.value)}</div>
        ) : (
          ""
        )}
        <div className="kpi-value-text">{props.valueText}</div>
      </div>
    );
  };

  const renderComparator = () => {
    return (
      <div>
        {props.comparatorText} <span>{Math.round(props.comparator)}</span>{" "}
        {props.caret &&
          renderCaret(Math.round(props.value), Math.round(props.comparator))}
      </div>
    );
  };

  if (props.comparator && (props.value || props.valueText)) {
    return (
      <div className="kpi-component">
        {renderKPI()}
        <Divider className="divider" />
        {renderComparator()}
      </div>
    );
  }
  if (props.value || props.value === 0 || props.valueText) {
    return (
      <div className="kpi-component">
        {renderKPI()}
        <Divider className="divider" />
      </div>
    );
  }
  return null;
};

export default KPI;
