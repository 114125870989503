import React, { useState } from "react";
import ChartRenderer from "../components/ChartRenderer";
import {
  DashboardItem,
  Dashboard,
  GranularitySelector,
  ChartDescription,
} from "../components/Utils";
import Query from "../query/Query";
import CONSTANT, { getGranularity } from "./Constant";

DashboardItem.defaultProps = {
  size: 12,
};

const FrenchPropertyLinksPage = () => {
  const [granularity, setGranularity] = useState({});
  const [displaySerie, setDisplaySerie] = useState({});

  return (
    <Dashboard key={"frenchpropertylinks"} rowKey="frenchpropertylinks">
      <DashboardItem
        title={[
          "Leads count for the French property links portal",
          GranularitySelector(setGranularity, 1),
          ChartDescription(
            "Leads received for the French property links portal over time",
            "left"
          ),
        ]}
        direction="left"
        extra={ChartDescription("Lead")}
        size={CONSTANT.DASHBOARD_SIZE}
        height={CONSTANT.DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              ...Query.leadsForPortal(
                "FRENCH PROPERTY LINKS",
                getGranularity.bind(null, 1, granularity)
              ),
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
    </Dashboard>
  );
};

export default FrenchPropertyLinksPage;
