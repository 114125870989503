export const Query = {
  leadsForPortal: (portalName, granularityFctn) => {
    return {
      measures: ["Leads.count"],
      timeDimensions: [
        {
          dimension: "Leads.date",
          granularity: granularityFctn(),
        },
      ],
      order: {
        "Leads.date": "asc",
      },
      dimensions: [],
      filters: [
        {
          member: "Leads.platform",
          operator: "equals",
          values: [portalName],
        },
      ],
    };
  },
  leadsByPortal: () => {
    return {
      measures: ["Leads.count"],
      timeDimensions: [],
      order: {
        "Leads.count": "desc",
      },
      dimensions: ["Leads.platform"],
    };
  },
  reportsForPortal: (portalName, reportType, granularityFctn) => {
    return {
      measures: ["Reports.count"],
      timeDimensions: [
        {
          dimension: "Reports.date",
          granularity: granularityFctn(),
        },
      ],
      order: {
        "Reports.date": "asc",
      },
      dimensions: [],
      filters: [
        {
          member: "Reports.platform",
          operator: "equals",
          values: [portalName],
        },
        {
          member: "Reports.reporttype",
          operator: "equals",
          values: [reportType],
        },
      ],
    };
  },
  numberOfViews: (platformName) => {
    return {
      measures: ["Reports.viewcount"],
      timeDimensions: [
        {
          dimension: "Reports.date",
          granularity: "day",
        },
      ],
      order: {
        "Reports.date": "asc",
      },
      filters: [
        {
          member: "Reports.platform",
          operator: "equals",
          values: [platformName],
        },
        {
          member: "Reports.reporttype",
          operator: "equals",
          values: ["PROPERTY"],
        },
      ],
    };
  },
  dailyViewCountQuery: (platformName, granularityFctn) => {
    return {
      measures: ["Reports.dailyViewCount"],
      timeDimensions: [
        {
          dimension: "Reports.date",
          granularity: granularityFctn(),
        },
      ],
      order: {
        "Reports.date": "asc",
      },
      filters: [
        {
          member: "Reports.platform",
          operator: "equals",
          values: [platformName],
        },
      ],
    };
  },
  emailCountQuery: (platformName, granularityFctn) => {
    return {
      measures: ["Reports.emailcount"],
      timeDimensions: [
        {
          dimension: "Reports.date",
          granularity: granularityFctn(),
        },
      ],
      order: {
        "Reports.date": "asc",
      },
      filters: [
        {
          member: "Reports.platform",
          operator: "contains",
          values: [platformName],
        },
      ],
    };
  },
  publishedProperties: (platformName, granularityFctn) => {
    return {
      measures: ["Reports.count"],
      timeDimensions: [
        {
          dimension: "Reports.date",
          granularity: granularityFctn(),
        },
      ],
      order: {
        "Reports.date": "asc",
      },
      filters: [
        {
          member: "Reports.platform",
          operator: "contains",
          values: [platformName],
        },
      ],
    };
  },
  dailyEmailCountQuery: (platformName, granularityFctn) => {
    return {
      measures: ["Reports.dailyEmail"],
      timeDimensions: [
        {
          dimension: "Reports.date",
          granularity: granularityFctn(),
        },
      ],
      order: {
        "Reports.date": "asc",
      },
      filters: [
        {
          member: "Reports.platform",
          operator: "equals",
          values: [platformName],
        },
      ],
    };
  },
  dailyPhoneCallCountQuery: (platformName, granularityFctn) => {
    return {
      measures: ["Reports.dailyPhoneCall"],
      timeDimensions: [
        {
          dimension: "Reports.date",
          granularity: granularityFctn(),
        },
      ],
      order: {
        "Reports.date": "asc",
      },
      filters: [
        {
          member: "Reports.platform",
          operator: "equals",
          values: [platformName],
        },
      ],
    };
  },
  conversionQuery: (platformName, granularityFctn) => {
    return {
      measures: ["Reports.conversion"],
      timeDimensions: [
        {
          dimension: "Reports.date",
          granularity: granularityFctn(),
        },
      ],
      order: {
        "Reports.date": "asc",
      },
      filters: [
        {
          member: "Reports.platform",
          operator: "equals",
          values: [platformName],
        },
      ],
    };
  },
};

export default Query;
