import React, { useState, useEffect } from "react";
import ChartRenderer from "../components/ChartRenderer";
import {
  Dashboard,
  DashboardItem,
  ChartDescription,
} from "../components/Utils";
import "./style/property-list.scss";
import PropertiesMapPage from "./PropertyMapPage";
import { useCubeQuery } from "@cubejs-client/react";
import DataChecker from "../components/DataChecker";
import DepartmentKpi from "../components/DepartmentKpi";
export const rightmoveDateQuery = {
  dimensions: ["Performancereports.date"],
  timeDimensions: [],
  order: [["Performancereports.date", "desc"]],
  filters: [],
  limit: 1,
};

export const reportDateQuery = {
  dimensions: ["Reports.date"],
  timeDimensions: [],
  order: [["Reports.date", "desc"]],
  filters: [],
  limit: 1,
};

export const lastInsertDate = {
  measures: [],
  timeDimensions: [
    {
      dimension: "Adaptimmos.insertdate",
      granularity: "day",
    },
  ],
  order: [["Adaptimmos.insertdate", "desc"]],
  limit: 1,
  filters: [],
};

const queryDepartmentsPopulation = {
  measures: ["Adaptimmos.count"],
  timeDimensions: [
    {
      dimension: "Adaptimmos.insertdate",
      dateRange: "Today",
    },
  ],
  order: {
    "Adaptimmos.count": "desc",
  },
  dimensions: ["Adaptimmos.department"],
  filters: [
    {
      member: "Adaptimmos.platform",
      operator: "equals",
      values: ["ADAPTIMMO"],
    },
  ],
  limit: 5000,
};

const PropertyListPage = () => {
  const [propertiesData, setPropertiesData] = useState({});
  const { resultSet, isLoading } = useCubeQuery(rightmoveDateQuery);
  const requestLastInsertDate = useCubeQuery(lastInsertDate);

  useEffect(() => {
    if (!isLoading && resultSet) {
      setPropertiesData((previousState) => ({
        ...previousState,
        date: resultSet.loadResponses[0].data[0]["Performancereports.date"],
      }));
    }
    if (!requestLastInsertDate.isLoading && requestLastInsertDate.resultSet) {
      setPropertiesData((previousState) => ({
        ...previousState,
        lastInsertDate:
          requestLastInsertDate.resultSet.loadResponses[0].data[0][
            "Adaptimmos.insertdate"
          ],
      }));
    }
  }, [
    isLoading,
    resultSet,
    requestLastInsertDate.isLoading,
    requestLastInsertDate.resultSet,
    propertiesData.departmentZip,
  ]);

  return (
    <div key="property-list-page" className="property-list-container">
      <Dashboard key="property-list">
        {ChartRenderer({
          vizState: {
            query: {
              measures: [],
              timeDimensions: [
                {
                  dimension: "Reports.date",
                  dateRange: "Yesterday",
                },
              ],
              order: [["Reports.castMandat", "desc"]],
              dimensions: [
                "Reports.mandat",
                "Reports.agentEmail",
                "Reports.propertyLink",
                "Reports.castMandat",
                "Reports.propertyId",
                "Reports.platform",
              ],
              filters: [
                {
                  member: "Reports.mandat",
                  operator: "set",
                },
                {
                  member: "Reports.agentEmail",
                  operator: "set",
                },
              ],
            },
            chartType: "propertiesTable",
            pivotConfig: {
              x: [],
              y: [],
              fillMissingDates: true,
              joinDateRange: false,
            },
          },
        })}
      </Dashboard>
      <Dashboard>
        <PropertiesMapPage
          title="Heatmap of properties count"
          description="HeatMap of the properties distribution in France for each region"
          query={queryDepartmentsPopulation}
          mapType={"departements.json"}
          type={"Adaptimmos.department"}
          count={"Adaptimmos.count"}
          geoType={"code"}
        />
        <DashboardItem title="Top #5 properties by lead count">
          {ChartRenderer({
            vizState: {
              query: {
                measures: ["Leads.count"],
                timeDimensions: [],
                order: {
                  "Leads.count": "desc",
                },
                dimensions: ["Leads.propertyReference"],
                limit: 5,
              },
              chartType: "propertiesTable",
              pivotConfig: null,
            },
          })}
        </DashboardItem>
        <DashboardItem
          title={[
            `Most represented department`,
            ChartDescription(
              `Number of properties located in the most represented department`,
              "right"
            ),
          ]}
        >
          <DataChecker data={propertiesData.lastInsertDate}>
            <DepartmentKpi date={propertiesData.lastInsertDate} />
          </DataChecker>
        </DashboardItem>
      </Dashboard>
    </div>
  );
};

export default PropertyListPage;
