import React from "react";
import { withRouter } from "react-router";
import { Layout, Menu, Avatar } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import {
  ADMIN_ROLE_KEY,
  getRoleHomePage,
} from "../authentication/authenticated-route";

const Header = (props) => {
  const { user, logout } = useAuth0();
  return (
    <Layout.Header
      style={{
        padding: "0 32px",
      }}
    >
      <div
        style={{
          float: "left",
          display: "flex",
          height: "64px",
        }}
      >
        <Link to={getRoleHomePage(user[ADMIN_ROLE_KEY])}>
          <h2 style={{ color: "white" }}>
            <img
              style={{
                height: "28px",
                alignSelf: "center",
                marginTop: "-5px",
                transform: "rotate(346deg)",
              }}
              src={`${window.location.origin}/logosextant.png`}
            />
            <span style={{ marginLeft: "10px" }}>Sextant Analytics</span>
          </h2>
        </Link>
      </div>
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[props.location.pathname]}
        style={{
          lineHeight: "64px",
          lineWidth: "64px",
        }}
      >
        <Link to="/logout" style={{ color: "#A6ADB4" }}>
          <Menu.Item
            key="user"
            style={{ float: "right", display: "flex", marginRight: "30px" }}
          >
            <div>
              <Avatar style={{ marginRight: "10px" }} src={user.picture} />
            </div>
            <div>{user.nickname}</div>
          </Menu.Item>
        </Link>
      </Menu>
    </Layout.Header>
  );
};

export default withRouter(Header);
