import React from "react";
import ChartRenderer from "../components/ChartRenderer";
import { useCubeQuery } from "@cubejs-client/react";
import {
  DashboardItem,
  Dashboard,
  ChartDescription,
  AreaChartLegend,
} from "../components/Utils";
import {
  DollarCircleOutlined,
  MailOutlined,
  EyeOutlined,
  PhoneOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import KPI from "../components/KPI";
import { ReferenceLine } from "recharts";
import { Tooltip } from "antd";
import moment from "moment";
import "./style/custom-chart.scss";

const pricesQuery = (propertyId) => ({
  measures: [],
  timeDimensions: [
    {
      dimension: "Adaptimmos.insertdate",
      granularity: "day",
    },
  ],
  order: {
    "Adaptimmos.insertdate": "desc",
  },
  filters: [
    {
      member: "Adaptimmos.mandat",
      operator: "equals",
      values: [propertyId],
    },
    {
      member: "Adaptimmos.price",
      operator: "set",
    },
  ],
  dimensions: ["Adaptimmos.price"],
});

const leadsQuery = (propertyId) => ({
  measures: ["Leads.count"],
  timeDimensions: [
    {
      dimension: "Leads.date",
      granularity: "day",
    },
  ],
  order: {
    "Leads.date": "asc",
  },
  filters: [
    {
      member: "Leads.mandat",
      operator: "equals",
      values: [propertyId],
    },
  ],
});

const bumpupQuery = (propertyId) => ({
  measures: [],
  timeDimensions: [
    {
      dimension: "Adaptimmos.insertdate",
      granularity: "day",
    },
  ],
  order: {
    "Adaptimmos.insertdate": "asc",
  },
  filters: [
    {
      member: "Adaptimmos.mandat",
      operator: "equals",
      values: [propertyId],
    },
  ],
  dimensions: ["Adaptimmos.reference"],
});

const leadQueryCount = (propertyId) => ({
  measures: ["Leads.count"],
  timeDimensions: [],
  filters: [
    {
      member: "Leads.mandat",
      operator: "equals",
      values: [propertyId],
    },
  ],
});

const dailyViewByPlatformQuery = (propertyId) => ({
  measures: ["Reports.dailyViewCount"],
  timeDimensions: [
    {
      dimension: "Reports.date",
      granularity: "day",
      dateRange: ["2021-06-18", moment().format("YYYY-MM-DD")],
    },
  ],
  order: {
    "Reports.date": "asc",
  },
  filters: [
    {
      member: "Reports.reporttype",
      operator: "equals",
      values: ["PROPERTY"],
    },
    {
      member: "Reports.mandat",
      operator: "equals",
      values: [propertyId],
    },
  ],
  dimensions: [],
});

const numberOfPhoneEnquiryQuery = (propertyId) => ({
  measures: ["Reports.phoneCallCount"],
  timeDimensions: [
    {
      dimension: "Reports.date",
    },
  ],
  order: {
    "Reports.date": "asc",
  },
  filters: [
    {
      member: "Reports.mandat",
      operator: "equals",
      values: [propertyId],
    },
  ],
  dimensions: [],
});

const viewCountQuery = (propertyId) => ({
  measures: ["Reports.viewcount"],
  timeDimensions: [
    {
      dimension: "Reports.date",
      granularity: "day",
      dateRange: "Yesterday",
    },
  ],
  order: [["Reports.viewcount", "desc"]],
  filters: [
    {
      member: "Reports.mandat",
      operator: "equals",
      values: [propertyId],
    },
  ],
  limit: 1,
});

/**
 * Display a custom HTML element
 * @param {*} props - component props
 * @returns {React.ReactElement[]}
 */
const CustomLabel = (props) => {
  return (
    <foreignObject
      style={{ width: "40px", height: "40px" }}
      className="label-wrapper"
      x={props.viewBox.x - 10}
      y={
        props.type === "lead"
          ? props.viewBox.y - props.pinSize
          : props.viewBox.height - props.pinSize
      }
    >
      <Tooltip key={`tooltip-${props.tooltipText}`} title={props.tooltipText}>
        <div className={"offer-item"} style={{ backgroundColor: props.color }}>
          <span className="offer-item-text">{props.text}</span>
          <div className={"offer-pin"} style={{ color: props.color }}></div>
        </div>
      </Tooltip>
    </foreignObject>
  );
};

/**
 * Display price changes on chart using a simple icon symbol
 * @param {Array<Object>} priceArr - array of price changes
 * @returns {React.ReactElement[]}
 */
const displayPriceChanges = (priceArr) => {
  const defaultPrice = priceArr?.[0]?.["Adaptimmos.price"] || 0;
  const PIN_SIZE = 32;
  const changes =
    priceArr?.reduce(
      (acc, currentValue) => {
        if (
          parseInt(currentValue?.["Adaptimmos.price"]) < acc[acc.length - 1]
        ) {
          return [...acc, currentValue];
        }
        return [...acc];
      },
      [defaultPrice]
    ) || [];
  return changes.map((change) => (
    <ReferenceLine
      x={change?.["Adaptimmos.insertdate.day"]}
      segment={[
        { x: change?.["Adaptimmos.insertdate.day"], y: 0 },
        { x: change?.["Adaptimmos.insertdate.day"], y: 0 },
      ]}
      strokeWidth={0}
      label={
        <CustomLabel
          pinSize={PIN_SIZE}
          type="price"
          color="#188FA0"
          text="€"
          tooltipText={`Price updated: ${change?.["Adaptimmos.price"]}`}
        />
      }
    />
  ));
};

const distinctObject = (arr, key) => {
  const res = [];
  arr.filter((item) => {
    if (res.findIndex((x) => x[key] == item[key]) <= -1) {
      res.push(item);
    }
  });
  return res;
};

const displayBumpup = (referenceArr) => {
  const defaultReference = referenceArr?.[0];
  const PIN_SIZE = 32;
  const changes =
    referenceArr?.reduce(
      (acc, currentValue) => {
        if (
          currentValue?.["Adaptimmos.reference"] !==
          acc[acc.length - 1]?.["Adaptimmos.reference"]
        ) {
          return [...acc, currentValue];
        }
        return [...acc];
      },
      [defaultReference]
    ) || [];

  /* avoid too many pin when multiple ACTIVE references are available through days */
  const distinctChanges = distinctObject(changes, "Adaptimmos.reference");

  return distinctChanges.map((change) => (
    <ReferenceLine
      x={change?.["Adaptimmos.insertdate.day"]}
      segment={[
        { x: change?.["Adaptimmos.insertdate.day"], y: 0 },
        { x: change?.["Adaptimmos.insertdate.day"], y: 0 },
      ]}
      strokeWidth={0}
      label={
        <CustomLabel
          pinSize={PIN_SIZE}
          type="bumpup"
          color="#096dd9"
          text={<NotificationOutlined />}
          tooltipText={`Remontée d'annonce #${change?.["Adaptimmos.reference"]}`}
        />
      }
    />
  ));
};

const displayLeads = (leadsArr, data) => {
  const PIN_SIZE = 37;
  return leadsArr.map((lead) => {
    const d = data.find((d) => d["Reports.date"] === lead?.["Leads.date.day"]);
    return (
      <ReferenceLine
        segment={[
          { x: lead?.["Leads.date.day"], y: d?.["Reports.dailyViewCount"] },
          { x: lead?.["Leads.date.day"], y: d?.["Reports.dailyViewCount"] },
        ]}
        strokeWidth={0}
        label={
          <CustomLabel
            pinSize={PIN_SIZE}
            type="lead"
            color="#FF685E"
            text="L"
            tooltipText={`Number of leads: ${lead?.["Leads.count"]}`}
          />
        }
      />
    );
  });
};

const PropertyDashboard = (props) => {
  const [leads, setLeads] = React.useState({ lead: [], count: 0 });
  const [displaySerie, setDisplaySerie] = React.useState({});
  const KPIStats = useCubeQuery([
    pricesQuery(props.id),
    viewCountQuery(props.id),
    numberOfPhoneEnquiryQuery(props.id),
    bumpupQuery(props.id),
  ]);
  const leadsRequest = useCubeQuery([
    leadsQuery(props.id),
    leadQueryCount(props.id),
  ]);
  const [responseKPIData, setResponseKPIData] = React.useState({});

  React.useEffect(() => {
    if (!leadsRequest.isLoading && leadsRequest.resultSet) {
      setLeads((previousState) => ({
        ...previousState,
        lead: leadsRequest.resultSet.loadResponses[0].data,
        count:
          leadsRequest.resultSet.loadResponses[1].data?.[0]?.["Leads.count"],
      }));
    }
    if (!KPIStats.isLoading && KPIStats.resultSet) {
      setResponseKPIData((previousState) => ({
        ...previousState,
        prices: KPIStats.resultSet.loadResponses[0].data,
        viewCount: KPIStats.resultSet.loadResponses[1].data,
        numPhone: KPIStats.resultSet.loadResponses[2].data,
        bumpup: KPIStats.resultSet.loadResponses[3].data,
      }));
    }
  }, [
    leadsRequest.isLoading,
    leadsRequest.resultSet,
    KPIStats.isLoading,
    KPIStats.resultSet,
  ]);

  return (
    <Dashboard>
      <DashboardItem
        title={[
          "Property price",
          ChartDescription("Latest property price", "right"),
        ]}
        size={6}
        height={95}
        style={{ float: "left" }}
      >
        <KPI
          icon={
            <DollarCircleOutlined
              style={{ fontSize: "40px", color: "#52c41a" }}
            />
          }
          value={
            responseKPIData?.prices?.[responseKPIData.prices.length - 1]?.[
              "Adaptimmos.price"
            ] || 0
          }
        />
      </DashboardItem>

      <DashboardItem
        title={[
          "Displays on property details",
          ChartDescription(
            "Total number of views for the property calculated with all the reports platform stats, we use the yesterday date to query the data",
            "right"
          ),
        ]}
        size={6}
        height={95}
        style={{ float: "left" }}
      >
        <KPI
          icon={<EyeOutlined style={{ fontSize: "40px", color: "#188FA0" }} />}
          value={
            responseKPIData?.viewCount?.[
              responseKPIData.viewCount.length - 1
            ]?.["Reports.viewcount"] || 0
          }
        />
      </DashboardItem>

      <DashboardItem
        title={[
          "Email contacts",
          ChartDescription(
            "Number of leads received for the property",
            "right"
          ),
        ]}
        size={6}
        height={95}
        style={{ float: "left" }}
      >
        <KPI
          icon={<MailOutlined style={{ fontSize: "40px", color: "#7A77FF" }} />}
          value={leads?.count || 0}
        />
      </DashboardItem>

      <DashboardItem
        title={[
          "Phone contacts",
          ChartDescription(
            "Number of phone enquiries for the property",
            "right"
          ),
        ]}
        size={6}
        height={95}
        style={{ float: "left" }}
      >
        <KPI
          icon={
            <PhoneOutlined style={{ fontSize: "40px", color: "#C6878F" }} />
          }
          value={
            responseKPIData?.numPhone?.[responseKPIData.numPhone.length - 1]?.[
              "Reports.phoneCallCount"
            ] || 0
          }
        />
      </DashboardItem>

      <DashboardItem title="Daily views of real estate platforms" size={24}>
        <ChartRenderer
          displaySerie={displaySerie}
          setDisplaySerie={setDisplaySerie}
          vizState={{
            query: dailyViewByPlatformQuery(props.id),
            chartType: "area",
            pivotConfig: {},
            dateFormat: "DD. MMM",
            chartTitle: "Daily views of real estate platforms",
          }}
          customHTML={(resultSet) => {
            return [
              displayLeads(leads.lead, resultSet.loadResponses[0].data),
              displayBumpup(responseKPIData?.bumpup),
              displayPriceChanges(responseKPIData?.prices),
            ];
          }}
          displayLegend={(resultSet) => {
            return AreaChartLegend(
              leads.lead,
              resultSet.loadResponses[0].data,
              responseKPIData?.prices
            );
          }}
        />
      </DashboardItem>

      <DashboardItem title={["Property Leads by day"]}>
        {ChartRenderer({
          vizState: {
            query: {
              measures: ["Leads.count"],
              timeDimensions: [
                {
                  dimension: "Leads.date",
                  granularity: "day",
                },
              ],
              order: {
                "Leads.date": "asc",
              },
              filters: [
                {
                  member: "Leads.mandat",
                  operator: "equals",
                  values: [props.id],
                },
              ],
            },
            chartType: "line",
            dateFormat: "DD. MMM",
            pivotConfig: null,
          },
        })}
      </DashboardItem>
    </Dashboard>
  );
};

export default PropertyDashboard;
