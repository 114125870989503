import React from "react";
import PropTypes from "prop-types";
import { useCubeQuery } from "@cubejs-client/react";
import { Spin, Row, Col, Statistic, Table, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Utils from "../utils/utils";
import moment from "moment";
import Pie from "./highcharts/Pie";
import {
  CartesianGrid,
  PieChart,
  Pie as RechartPie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
  ReferenceLine,
} from "recharts";

import "../pages/style/table.scss";
import "../pages/style/date-filter.scss";
import "../pages/style/date-picker.scss";
import "../pages/style/recharts.scss";
import {
  DateFilterSelector,
  PERIOD_FILTER_NAME,
  PICKER_FILTER_NAME,
} from "./QueryBuilder/DateFilter";
import DatePickerSelector from "./QueryBuilder/DatePicker";

const handleLegendClick = (e, setDisplaySerie, displaySerie) => {
  setDisplaySerie((previousState) => ({
    ...previousState,
    [e.dataKey]: !displaySerie[e.dataKey],
  }));
};

// Format is unused but set so we can access the third argument
function formatXAxis(XAxisLegend, format, dateFormat) {
  let formatDate = moment(XAxisLegend).format(dateFormat);
  return formatDate === "Invalid date" ? XAxisLegend : formatDate;
}

const GRANULARITY = {
  day: "days",
  week: "weeks",
  month: "months",
};

const fillMissingDates = (resultSet) => {
  try {
    const granularity =
      resultSet.loadResponses[0].query.timeDimensions?.[0]?.granularity;

    const dimensionName =
      resultSet.loadResponses[0].query.timeDimensions[0].dimension;
    const firstDate = resultSet.loadResponses[0].data?.[0]?.[dimensionName];
    const lastDate =
      resultSet.loadResponses[0].data?.[
        resultSet.loadResponses[0].data.length - 1
      ]?.[dimensionName];
    const range = Utils.getRange(
      firstDate,
      lastDate,
      GRANULARITY[granularity],
      "YYYY-MM-DD[T00:00:00.000]"
    );
    const seriesDates = resultSet.loadResponses?.[0]?.data.map(
      (o) => o[dimensionName]
    );
    const missingDates = range.filter(
      (date) => seriesDates.indexOf(date) === -1
    );
    missingDates.forEach((date) => {
      const newDate = resultSet.loadResponses?.[0]?.data[0];
      newDate[dimensionName] = date;
      newDate[resultSet.loadResponses[0].query.measures[0]] = 0;
      resultSet.loadResponses?.[0]?.data.push(newDate);
    });
  } catch (e) {
    console.warn("Could not fill missing date: e", e);
  }
};

/**
 * Get average value for a serie
 * @param {Array<Object>} data - points
 * @param {String} key - serie key name
 * @returns {Integer} - average value
 */
const getAvgSerieValues = (data, key) => {
  return (
    data
      .map((o) => o[key])
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
      data.length || 0
  );
};

/**
 * Get average value for a multiple series chart
 * @param {Array<Object>} data - points
 * @param {String} key - serie key name
 * @param {Integer} daycount - Number of day for the data array
 * @returns {Integer} - average value
 */
const getAvgSerieValuesForMultipleSeries = (data, key, daycount) => {
  const leadSum = data.reduce(
    (accumulator, currentValue) => accumulator + currentValue[key],
    0
  );
  return leadSum / daycount;
};

const sortSerieNames = (serieNames) =>
  serieNames.sort((a, b) => (a.key < b.key ? -1 : 1));

/**
 * Render series and associated average value
 * @param {Object} resultSet
 * @param {*} displaySerie
 * @param {boolean} averageDisplay - display average line
 * @param {React.ReactElement} customHTML - display custom element on chart
 * @returns {React.ReactElement[]} - array of React components
 */
const renderSerieWithAverage = (
  resultSet,
  displaySerie,
  averageDisplay,
  customHTML
) => {
  return sortSerieNames(resultSet.seriesNames()).map((series, i) => {
    fillMissingDates(resultSet, series.key);
    const averageValue = getAvgSerieValues(
      resultSet.loadResponses[0].data,
      series.key
    );
    return [
      <Line
        hide={
          resultSet.seriesNames().length > 1 &&
          displaySerie?.[series.key] === true
        }
        key={series.key}
        stackId="a"
        dataKey={series.key}
        name={series.yValues[0]}
        stroke={colors[i]}
        fillOpacity={1}
      />,
      averageDisplay ? (
        <ReferenceLine
          y={averageValue}
          strokeDasharray="3 3"
          label={`Average ${series.title} (${Math.round(averageValue)})`}
          stroke="red"
        />
      ) : null,
      customHTML ? customHTML : null,
    ];
  });
};

/**
 * Render an average value for multiple series charts
 * @param {Object} resultSet
 * @param {boolean} averageDisplay - display average line
 * @param {React.ReactElement} customHTML - display custom element on chart
 * @returns {React.ReactElement[]} - array of React components
 */
const renderMultipleSerieWithAverage = (
  resultSet,
  averageDisplay,
  customHTML
) => {
  const results = resultSet.loadResponses[0];
  if (results.data.length === 0) {
    return null;
  }
  const endDate =
    results.data[results.data.length - 1][
      results.query?.timeDimensions?.[0]?.dimension
    ];
  const startDate =
    results.data[0][results.query?.timeDimensions?.[0]?.dimension];
  if (!endDate || !startDate) {
    return null;
  }
  const daycount = Utils.getDateDiff(endDate, startDate, "days");
  const averageValue = getAvgSerieValuesForMultipleSeries(
    results.data,
    results.query.measures[0],
    daycount
  );

  sortSerieNames(resultSet.seriesNames()).map((series) =>
    fillMissingDates(resultSet, series.key)
  );
  return [
    averageDisplay ? (
      <ReferenceLine
        y={averageValue}
        strokeDasharray="3 3"
        label={`Average  (${Math.round(averageValue)})`}
        stroke="red"
      />
    ) : null,
    customHTML ? customHTML : null,
  ];
};

const CartesianChart = ({
  resultSet,
  children,
  ChartComponent,
  setDisplaySerie,
  displaySerie,
  dateFormat,
  setDateRange,
  dateRange,
  dateFilterType,
  defaultDateValue,
}) => (
  <>
    {dateRange && dateFilterType === PERIOD_FILTER_NAME ? (
      <DateFilterSelector setDateRange={setDateRange} dateRange={dateRange} />
    ) : null}
    {dateRange && dateFilterType === PICKER_FILTER_NAME ? (
      <DatePickerSelector
        setDateRange={setDateRange}
        dateRange={dateRange}
        defaultValue={defaultDateValue}
      />
    ) : null}
    <ResponsiveContainer width="100%" height={350}>
      <ChartComponent data={resultSet.chartPivot()}>
        <XAxis
          dataKey="x"
          tickFormatter={(XAxisLegend, format) =>
            formatXAxis(XAxisLegend, format, dateFormat)
          }
        />
        <YAxis />
        <CartesianGrid />
        {children}
        <Legend
          onClick={(e) => handleLegendClick(e, setDisplaySerie, displaySerie)}
          wrapperStyle={{ cursor: "pointer" }}
        />
        <Tooltip itemSorter={(item) => -item.value} />
      </ChartComponent>
    </ResponsiveContainer>
  </>
);

const CartesianAreaChart = ({
  resultSet,
  children,
  ChartComponent,
  setDisplaySerie,
  displaySerie,
  dateFormat,
  chartTitle,
  setDateRange,
  dateRange,
}) => {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <ChartComponent data={resultSet.chartPivot()}>
        <XAxis
          dataKey="x"
          tickFormatter={(XAxisLegend, format) =>
            formatXAxis(XAxisLegend, format, dateFormat)
          }
        />
        <YAxis
          yAxisId="left"
          orientation="left"
          position="insideLeft"
          label={{ value: chartTitle, angle: -90, position: "Right" }}
        />
        <YAxis orientation="right" />
        <CartesianGrid vertical={false} stroke={"#565656"} strokeWidth={0.3} />
        {children}
        <Legend
          onClick={(e) => handleLegendClick(e, setDisplaySerie, displaySerie)}
          wrapperStyle={{ cursor: "pointer" }}
          iconType={"square"}
        />
        <Tooltip itemSorter={(item) => -item.value} />
      </ChartComponent>
    </ResponsiveContainer>
  );
};

const colors = [
  "#188FA0",
  "#64F58D",
  "#7A77FF",
  "#C6878F",
  "#B79D94",
  "#982649",
  "#F7ACCF",
  "#53F4FF",
  "#CFB1B7",
  "#BFA89E",
  "#48639C",
  "#FF4000",
  "#504B3A",
  "#64B6AC",
  "#28965A",
  "#C62E65",
  "#D63AF9",
  "#E6AF2E",
  "#141446",
  "#F2D591",
];

const areaStrokeColors = [
  "#188FA0",
  "#3A1772",
  "#FF8C42",
  "#8AB0AB",
  "#2F1847",
  "#ADA8B6",
];

const areaColors = [
  "#BBEFF6",
  "#D8A7CA",
  "#F2E863",
  "#2E5EAA",
  "#715AFF",
  "#307473",
  "#342fff",
  "#02e0d1",
  "#59ddb9",
  "#70c7ce",
  "#215eff",
  "#7f82d8",
];

const stackedChartData = (resultSet) => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ", "),
        color: resultSet.axisValuesString(yValues, ", "),
        measure: m && Number.parseFloat(m),
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data;
};
const TypeToChartComponent = {
  line: ({
    resultSet,
    setDisplaySerie,
    displaySerie,
    averageDisplay,
    customHTML,
    dateFormat,
    chartTitle,
    setDateRange,
    dateRange,
    dateFilterType,
    defaultDateValue,
  }) => {
    return (
      <CartesianChart
        resultSet={resultSet}
        ChartComponent={LineChart}
        setDisplaySerie={setDisplaySerie}
        displaySerie={displaySerie}
        dateFormat={dateFormat}
        chartTitle={chartTitle}
        setDateRange={setDateRange}
        dateRange={dateRange}
        dateFilterType={dateFilterType}
        defaultDateValue={defaultDateValue}
      >
        {renderSerieWithAverage(
          resultSet,
          displaySerie,
          averageDisplay,
          customHTML
        )}
      </CartesianChart>
    );
  },
  bar: ({
    resultSet,
    setDisplaySerie,
    displaySerie,
    averageDisplay,
    customHTML,
    useTitle,
    dateFormat,
    chartTitle,
    setDateRange,
    dateRange,
    dateFilterType,
    defaultDateValue,
  }) => {
    return (
      <CartesianChart
        resultSet={resultSet}
        ChartComponent={BarChart}
        setDisplaySerie={setDisplaySerie}
        displaySerie={displaySerie}
        dateFormat={dateFormat}
        chartTitle={chartTitle}
        setDateRange={setDateRange}
        dateRange={dateRange}
        dateFilterType={dateFilterType}
        defaultDateValue={defaultDateValue}
      >
        {sortSerieNames(resultSet.seriesNames()).map((series, i) => (
          <Bar
            hide={
              resultSet.seriesNames().length > 1 &&
              displaySerie?.[series.key] === true
            }
            key={series.key}
            stackId="a"
            dataKey={series.key}
            name={useTitle ? series.title : series.yValues[0]}
            fill={colors[i]}
            fillOpacity={1}
          />
        ))}
        {renderMultipleSerieWithAverage(resultSet, averageDisplay, customHTML)}
      </CartesianChart>
    );
  },
  area: ({
    resultSet,
    setDisplaySerie,
    displaySerie,
    dateFormat,
    chartTitle,
    customHTML,
    displayLegend,
    setDateRange,
    dateRange,
    dateFilterType,
    defaultDateValue,
  }) => {
    return (
      <>
        <CartesianAreaChart
          resultSet={resultSet}
          ChartComponent={AreaChart}
          setDisplaySerie={setDisplaySerie}
          displaySerie={displaySerie}
          dateFormat={dateFormat}
          chartTitle={chartTitle}
          setDateRange={setDateRange}
          dateRange={dateRange}
          dateFilterType={dateFilterType}
          defaultDateValue={defaultDateValue}
        >
          {sortSerieNames(resultSet.seriesNames()).map((series, i) => [
            <Area
              hide={
                resultSet.seriesNames().length > 1 &&
                displaySerie[series.key] === true
              }
              key={series.key}
              dataKey={series.key}
              name={series.title}
              stackId="a"
              stroke={
                resultSet.seriesNames().length === 1
                  ? areaStrokeColors[i]
                  : areaColors[i]
              }
              fill={areaColors[i]}
              fillOpacity={1}
            />,
            typeof customHTML === "function" ? customHTML(resultSet) : null,
          ])}
        </CartesianAreaChart>
        <div>
          {typeof displayLegend === "function"
            ? displayLegend(resultSet)
            : null}
        </div>
      </>
    );
  },
  highchartsPie: ({ resultSet }) => {
    const dimensionName = Object.keys(
      resultSet.loadResponses?.[0].annotation.dimensions
    )[0];
    const measureName = Object.keys(
      resultSet.loadResponses?.[0].annotation.measures
    )[0];
    const highChartsData = resultSet.loadResponses?.[0].data.map((point) => ({
      name: point[dimensionName],
      y: point[measureName],
    }));
    return <Pie data={highChartsData} />;
  },
  pie: ({ resultSet }) => {
    return (
      <ResponsiveContainer width="100%" height={350}>
        <PieChart>
          <RechartPie
            isAnimationActive={false}
            data={resultSet.chartPivot()}
            nameKey="x"
            dataKey={resultSet.seriesNames()?.[0]?.key}
            fill="#8884d8"
          >
            {resultSet.chartPivot().map((e, index) => (
              <Cell key={index} fill={colors[index % colors.length]} />
            ))}
          </RechartPie>
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  },
  number: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: "100%",
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
            <Statistic value={resultSet.totalRow()[s.key]} />
          ))}
        </Col>
      </Row>
    );
  },
  table: ({ resultSet, pivotConfig, columnConfig, cssClasses, tableKey }) => {
    /* add search on table */
    const columns = resultSet
      .tableColumns(pivotConfig)
      .map((item) => ({ ...item, ...getColumnSearchProps(item.dataIndex) }));

    if (typeof columnConfig === "function") {
      columnConfig(columns);
    }

    return (
      <Table
        key={tableKey}
        className={cssClasses}
        pagination={false}
        columns={columns}
        dataSource={resultSet.tablePivot(pivotConfig)}
        scroll={{ y: 300 }}
      />
    );
  },
  // TODO refactor this method to be more generic
  propertiesTable: ({ resultSet, pivotConfig }) => {
    const columns = resultSet
      .tableColumns(pivotConfig)
      .map((item) => ({ ...item, ...getColumnSearchProps(item.dataIndex) }));
    columns[0].render = (text) => (
      <a href={`#/dashboard/properties/${text}`}> {text}</a>
    );
    if (columns[2]) {
      columns[2].render = (text) => (
        <a href={`${Utils.getLink(text)}`} rel="noreferrer" target="_blank">
          {Utils.setLink(text)}
        </a>
      );
    }
    return (
      <Table
        key="propertiesTable"
        pagination={true}
        columns={columns}
        bordered
        dataSource={resultSet.tablePivot(pivotConfig)}
      />
    );
  },
  agentsTable: ({ resultSet, pivotConfig }) => {
    const columns = resultSet
      .tableColumns(pivotConfig)
      .map((item) => ({ ...item, ...getColumnSearchProps(item.dataIndex) }));
    columns[0].render = (text) => (
      <a href={`#/dashboard/agent/${text}`}> {text}</a>
    );
    return (
      <Table
        pagination={true}
        columns={columns}
        bordered
        dataSource={resultSet.tablePivot(pivotConfig)}
      />
    );
  },
};

let getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : "",
  render: (text) => text,
});

const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart =
  (Component) =>
  ({
    resultSet,
    error,
    pivotConfig,
    setDisplaySerie,
    displaySerie,
    averageDisplay,
    hideLegend,
    customHTML,
    displayLegend,
    columnConfig,
    cssClasses,
    tableKey,
    useTitle,
    dateFormat,
    chartTitle,
    setDateRange,
    dateRange,
    dateFilterType,
    defaultDateValue,
  }) =>
    (resultSet && (
      <Component
        resultSet={resultSet}
        pivotConfig={pivotConfig}
        setDisplaySerie={setDisplaySerie}
        displaySerie={displaySerie}
        averageDisplay={averageDisplay}
        hideLegend={hideLegend}
        customHTML={customHTML}
        displayLegend={displayLegend}
        columnConfig={columnConfig}
        cssClasses={cssClasses}
        tableKey={tableKey}
        useTitle={useTitle}
        dateFormat={dateFormat}
        chartTitle={chartTitle}
        setDateRange={setDateRange}
        dateRange={dateRange}
        dateFilterType={dateFilterType}
        defaultDateValue={defaultDateValue}
      />
    )) ||
    (error && error.toString()) || <Spin />;

const ChartRenderer = ({
  averageDisplay,
  displaySerie,
  setDisplaySerie,
  vizState,
  hideLegend,
  customHTML,
  displayLegend,
  setDateRange,
  dateRange,
  dateFilterType,
  defaultDateValue,
}) => {
  const {
    query,
    chartType,
    pivotConfig,
    columnConfig,
    cssClasses,
    tableKey,
    useTitle,
    dateFormat,
    chartTitle,
  } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  return (
    component &&
    renderChart(component)({
      ...renderProps,
      pivotConfig,
      setDisplaySerie,
      displaySerie,
      hideLegend,
      averageDisplay,
      customHTML,
      displayLegend,
      columnConfig,
      cssClasses,
      tableKey,
      useTitle,
      dateFormat,
      chartTitle,
      setDateRange,
      dateRange,
      dateFilterType,
      defaultDateValue,
    })
  );
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
  cubejsApi: PropTypes.object,
};
ChartRenderer.defaultProps = {
  vizState: {},
  cubejsApi: null,
};
export default ChartRenderer;
