import { getColumnSearchProps, getColumnRangeProps } from "./Utils";

const priceMarks = {
  0: {
    style: {
      color: "#000",
    },
    label: "0 €",
  },
  1000000: {
    style: {
      width: "100%",
      color: "#000",
    },
    label: "1000000 €",
  },
};

const taxMarks = {
  0: {
    style: {
      color: "#000",
    },
    label: "0 €",
  },
  5000: {
    style: {
      width: "100%",
      color: "#000",
    },
    label: "5000 €",
  },
};

const monthlyFeeMarks = {
  0: {
    style: {
      color: "#000",
    },
    label: "0 €",
  },
  500: {
    style: {
      width: "100%",
      color: "#000",
    },
    label: "500 €",
  },
};

const monthlyRentMarks = {
  0: {
    style: {
      color: "#000",
    },
    label: "0 €",
  },
  5000: {
    style: {
      width: "100%",
      color: "#000",
    },
    label: "5000 €",
  },
};

const annualRentMarks = {
  0: {
    style: {
      color: "#000",
    },
    label: "0 €",
  },
  50000: {
    style: {
      width: "100%",
      color: "#000",
    },
    label: "50000 €",
  },
};

const copropertyMarks = {
  0: {
    style: {
      color: "#000",
    },
    label: "0 €",
  },
  5000: {
    style: {
      width: "100%",
      color: "#000",
    },
    label: "5000 €",
  },
};

const brutRentabilityMarks = {
  0: {
    style: {
      color: "#000",
    },
    label: "0 %",
  },
  10: {
    style: {
      width: "100%",
      color: "#000",
    },
    label: "10 %",
  },
};

const tripleNetRentMarks = {
  0: {
    style: {
      color: "#000",
    },
    label: "0 €",
  },
  3500: {
    style: {
      width: "100%",
      color: "#000",
    },
    label: "3500 €",
  },
};

const tripleNetAnnualRentMarks = {
  0: {
    style: {
      color: "#000",
    },
    label: "0 €",
  },
  60000: {
    style: {
      width: "100%",
      color: "#000",
    },
    label: "60000 €",
  },
};

const netRentabilityMarks = {
  0: {
    style: {
      color: "#000",
    },
    label: "0 %",
  },
  10: {
    style: {
      width: "100%",
      color: "#000",
    },
    label: "10 %",
  },
};

const areaMarks = {
  0: {
    style: {
      color: "#000",
    },
    label: "0 m²",
  },
  500: {
    style: {
      width: "100%",
      color: "#000",
    },
    label: "10 m²",
  },
};

export const columns = (filteredColumnsList) => {
  return [
    {
      title: "Mandat",
      dataIndex: "mandat",
      width: 100,
      fixed: "left",
      sorter: (a, b) => parseInt(a.mandat) - parseInt(b.mandat),
      ...getColumnSearchProps("mandat", false, false, filteredColumnsList),
    },
    {
      title: "Etat",
      dataIndex: "etat",
      width: 100,
      fixed: "left",
      filters: [
        { text: "Actif", value: "Actif" },
        { text: "Sous offre", value: "Sous offre" },
        { text: "Vendu", value: "Vendu" },
      ],
      defaultFilteredValue: ["Actif", "Sous offre"],
      onFilter: (value, record) => record.etat.includes(value),
    },
    {
      title: "Photo",
      dataIndex: "photo",
      width: 110,
      fixed: "left",
      render: (text, record) => (
        <a
          href={`${record["Titre + Lien Web"]}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={text?.photo?.[0]} width="100" height="72" alt="" />
        </a>
      ),
    },
    {
      title: "Code postal",
      dataIndex: "code_postal",
      sorter: (a, b) => a.code_postal - b.code_postal,
      ...getColumnSearchProps("code_postal", false, false, filteredColumnsList),
      width: 100,
    },
    {
      title: "Ville",
      dataIndex: "ville",
      sorter: (a, b) => a?.ville?.localeCompare(b?.ville),
      ...getColumnSearchProps("ville", false, true, filteredColumnsList),
      width: 100,
    },
    {
      title: "Prix",
      dataIndex: "prix",
      sorter: (a, b) => parseInt(a.prix) - parseInt(b.prix),
      ...getColumnRangeProps("prix", 0, 1000000, "Tranche de prix", priceMarks),
      width: 100,
    },
    {
      title: "Environnement",
      dataIndex: "environnement",
      ...getColumnSearchProps(
        "environnement",
        false,
        true,
        filteredColumnsList
      ),
      width: 140,
    },
    {
      title: "Nom occupant",
      dataIndex: "nom_occupant",
      ...getColumnSearchProps("nom_occupant", false, true, filteredColumnsList),
      width: 100,
    },
    {
      title: "Type résidence",
      dataIndex: "type_résidence",
      filters: [
        { text: "Affaires", value: "Résidence Affaires" },
        { text: "Ehpad", value: "Résidence Ehpad" },
        { text: "Etudiante", value: "Résidence Etudiante" },
        { text: "Tourisme", value: "Résidence Tourisme" },
        { text: "Seniors", value: "Résidence Seniors" },
        { text: "Terrain", value: "Terrain constructible" },
      ],
      onFilter: (value, record) => record["type_résidence"].includes(value),
      width: 100,
    },
    {
      title: "Taxe foncière",
      dataIndex: "taxe_fonciere",
      sorter: (a, b) => parseInt(a.taxe_fonciere) - parseInt(b.taxe_fonciere),
      ...getColumnRangeProps(
        "taxe_fonciere",
        0,
        5000,
        "Tranche de taxes",
        taxMarks
      ),
      width: 105,
    },
    {
      title: "Charges mensuelles",
      dataIndex: "charges_mensuelles",
      sorter: (a, b) =>
        parseInt(a.charges_mensuelles) - parseInt(b.charges_mensuelles),
      ...getColumnRangeProps(
        "charges_mensuelles",
        0,
        500,
        "Tranche de charges",
        monthlyFeeMarks
      ),
      width: 120,
    },
    {
      title: "Loyer mensuel",
      dataIndex: "loyer_mensuel_occupant",
      sorter: (a, b) =>
        parseInt(a["loyer_mensuel_occupant"]) -
        parseInt(b["loyer_mensuel_occupant"]),
      ...getColumnRangeProps(
        "loyer_mensuel_occupant",
        0,
        5000,
        "Tranche de loyer",
        monthlyRentMarks
      ),
      width: 110,
    },
    {
      title: "Loyer annuel",
      dataIndex: "loyer_annuel",
      sorter: (a, b) =>
        parseInt(a["loyer_annuel"]) - parseInt(b["loyer_annuel"]),
      ...getColumnRangeProps(
        "loyer_annuel",
        0,
        50000,
        "Tranche de loyer",
        annualRentMarks
      ),
      width: 100,
    },
    {
      title: "Copropriete charges annuelles",
      dataIndex: "copropriete_charges_annuelles",
      sorter: (a, b) =>
        parseInt(a.copropriete_charges_annuelles) -
        parseInt(b.copropriete_charges_annuelles),
      ...getColumnRangeProps(
        "copropriete_charges_annuelles",
        0,
        5000,
        "Tranche de charges",
        copropertyMarks
      ),
      width: 125,
    },
    {
      title: "Rentabilité Brute",
      dataIndex: "rentabilité_brute",
      sorter: (a, b) =>
        parseFloat(a["rentabilité_brute"]) - parseFloat(b["rentabilité_brute"]),
      ...getColumnRangeProps(
        "rentabilité_brute",
        0,
        10,
        "Tranche de rentabilité",
        brutRentabilityMarks
      ),
      width: 115,
    },
    {
      title: "Loyer double net mensuel",
      dataIndex: "loyer_mensuel_triple_net",
      sorter: (a, b) =>
        parseInt(a["loyer_mensuel_triple_net"]) -
        parseInt(b["loyer_mensuel_triple_net"]),
      ...getColumnRangeProps(
        "loyer_mensuel_triple_net",
        0,
        3500,
        "Tranche de loyer",
        tripleNetRentMarks
      ),
      width: 110,
    },
    {
      title: "Loyer double net annuel",
      dataIndex: "loyer_annuel_triple_net",
      sorter: (a, b) =>
        parseInt(a["loyer_annuel_triple_net"]) -
        parseInt(b["loyer_annuel_triple_net"]),
      ...getColumnRangeProps(
        "loyer_annuel_triple_net",
        0,
        60000,
        "Tranche de loyer",
        tripleNetAnnualRentMarks
      ),
      width: 110,
    },
    {
      title: "Rentabilité nette",
      dataIndex: "rentabilité_nette",
      sorter: (a, b) =>
        parseFloat(a["rentabilité_nette"]) - parseFloat(b["rentabilité_nette"]),
      ...getColumnRangeProps(
        "rentabilité_nette",
        0,
        10,
        "Tranche de rentabilité",
        netRentabilityMarks
      ),
      width: 115,
    },
    {
      title: "Date fin bail",
      dataIndex: "date_fin_bail",
      sorter: (a, b) =>
        new Date(a.date_fin_bail).getTime() -
        new Date(b.date_fin_bail).getTime(),
      ...getColumnSearchProps(
        "date_fin_bail",
        true,
        false,
        filteredColumnsList
      ),
      width: 100,
    },
    {
      title: "Année construction",
      dataIndex: "année_construction",
      sorter: (a, b) =>
        parseInt(a.année_construction) - parseInt(b.année_construction),
      ...getColumnSearchProps(
        "année_construction",
        false,
        false,
        filteredColumnsList
      ),
      width: 130,
    },
    {
      title: "Surface m²",
      dataIndex: "surface_m2",
      sorter: (a, b) => parseInt(a["surface_m2"]) - parseInt(b["surface_m2"]),
      ...getColumnRangeProps(
        "surface_m2",
        0,
        500,
        "Tranche de surface",
        areaMarks
      ),
      width: 100,
    },
  ];
};
