import React, { useState } from "react";
import ChartRenderer from "../components/ChartRenderer";
import {
  DashboardItem,
  Dashboard,
  GranularitySelector,
  ChartDescription,
} from "../components/Utils";
import Query from "../query/Query";
import moment from "moment";
import { getGranularity } from "./Constant";
import {
  DATE_FILTER_KEY,
  PICKER_FILTER_NAME,
  ALL_DATA_FILTER,
} from "../components/QueryBuilder/DateFilter";
import { getDateRange } from "./Constant";

DashboardItem.defaultProps = {
  size: 12,
};

export const dailyViewAllPlatformQuery = {
  measures: ["Reports.dailyViewCount"],
  timeDimensions: [
    {
      dimension: "Reports.date",
      granularity: "day",
      dateRange: ["2021-06-18", moment().format("YYYY-MM-DD")],
    },
  ],
  order: {
    "Reports.dailyViewCount": "desc",
  },
  filters: [
    {
      member: "Reports.reporttype",
      operator: "equals",
      values: ["PROPERTY"],
    },
  ],
  dimensions: ["Reports.platform"],
};

export const dailyViewByPlatformQuery = {
  measures: ["Reports.dailyViewCount"],
  timeDimensions: [
    {
      dimension: "Reports.date",
      granularity: "day",
      dateRange: ["2021-06-18", moment().format("YYYY-MM-DD")],
    },
  ],
  order: {
    "Reports.date": "asc",
  },
  filters: [
    {
      member: "Reports.reporttype",
      operator: "equals",
      values: ["PROPERTY"],
    },
  ],
  dimensions: [],
};

export const LeadByCountry = {
  measures: ["Leads.count"],
  timeDimensions: [],
  order: {
    "Leads.count": "desc",
  },
  dimensions: ["Leads.applicantCountry"],
};

export const getViewCount = (platformName) => ({
  query: {
    measures: ["Reports.viewcount"],
    timeDimensions: [
      {
        dimension: "Reports.date",
        granularity: "day",
      },
    ],
    order: {
      "Reports.date": "asc",
    },
    filters: [
      {
        member: "Reports.reporttype",
        operator: "contains",
        values: ["PROPERTY"],
      },
      {
        member: "Reports.platform",
        operator: "equals",
        values: [platformName],
      },
    ],
    dimensions: [],
  },
  chartType: "area",
  pivotConfig: {},
  dateFormat: "DD. MMM",
});

const DashboardAdminPage = () => {
  const [granularity, setGranularity] = useState({});
  const [displaySerie, setDisplaySerie] = useState({});
  const [dateRange, setDateRange] = useState({
    [DATE_FILTER_KEY]: ALL_DATA_FILTER,
  });

  const DASHBOARD_SIZE = 12;
  const DASHBOARD_HEIGHT = 380;

  return (
    <Dashboard key={"dashboard-admin"} rowKey="dashboard-admin">
      <DashboardItem
        title={[
          "Number of leads received",
          GranularitySelector(setGranularity, 1),
          ChartDescription(
            "Number of leads received by day for all platforms (Rightmove, Greenacres, Bien ICI)",
            "left"
          ),
        ]}
        direction="left"
        size={DASHBOARD_SIZE}
        height={DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              measures: ["Leads.count"],
              timeDimensions: [
                {
                  dimension: "Leads.date",
                  granularity: getGranularity(1, granularity),
                },
              ],
              order: {
                "Leads.date": "asc",
              },
            },
            chartType: "line",
            pivotConfig: null,
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Number of leads by portal",
          GranularitySelector(setGranularity, 2),
          ChartDescription(
            `Lead received by portal of year ${moment().format(
              "YYYY"
            )} (Rightmove, Greenacres, Bien ici, ...).`,
            "left"
          ),
        ]}
        size={DASHBOARD_SIZE}
        height={DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          setDateRange,
          dateRange,
          dateFilterType: PICKER_FILTER_NAME,
          defaultDateValue: [
            moment(moment().format("YYYY/01/01"), "YYYY/MM/DD"),
            moment(moment().format("YYYY/MM/DD"), "YYYY/MM/DD"),
          ],
          vizState: {
            query: {
              measures: ["Leads.count"],
              timeDimensions: [
                {
                  dimension: "Leads.date",
                  granularity: getGranularity(2, granularity),
                  ...getDateRange(dateRange, [
                    moment().format("YYYY/01/01"),
                    moment().format("YYYY/MM/DD"),
                  ]),
                },
              ],
              order: {
                "Leads.count": "desc",
              },
              dimensions: ["Leads.platform"],
            },
            chartType: "bar",
            pivotConfig: {
              x: ["Leads.date.day"],
              y: ["Leads.platform", "measures"],
              fillMissingDates: true,
              joinDateRange: false,
            },
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Count of properties published on Rightmove",
          ChartDescription(
            "Number of properties published on Rightmove portal",
            "right"
          ),
        ]}
        size={DASHBOARD_SIZE}
        height={DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          averageDisplay: true,
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              measures: ["Performancereports.count"],
              timeDimensions: [],
              order: [["Performancereports.date", "asc"]],
              dimensions: ["Performancereports.date"],
              filters: [],
            },
            chartType: "line",
            pivotConfig: {
              x: ["Performancereports.lastUpdated.day"],
              y: ["measures"],
              fillMissingDates: true,
              joinDateRange: false,
            },
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Lead distribution by country",
          ChartDescription(
            "Lead distribution by country for all email leads",
            "left"
          ),
        ]}
        size={DASHBOARD_SIZE}
        height={DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          hideLegend: true,
          vizState: {
            query: LeadByCountry,
            chartType: "highchartsPie",
            pivotConfig: {},
          },
        })}
      </DashboardItem>
      <DashboardItem title="Lead distribution by platform">
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: Query.leadsByPortal(),
            chartType: "highchartsPie",
            pivotConfig: {},
          },
        })}
      </DashboardItem>
      <DashboardItem
        title={[
          "Count of properties published on Greenacres",
          ChartDescription(
            "Number on properties published on Greenacres portal",
            "left"
          ),
        ]}
        size={DASHBOARD_SIZE}
        height={DASHBOARD_HEIGHT}
      >
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              measures: ["Reports.count"],
              timeDimensions: [],
              order: [["Reports.date", "asc"]],
              dimensions: ["Reports.date"],
              filters: [
                {
                  member: "Reports.platform",
                  operator: "equals",
                  values: ["greenacres"],
                },
                {
                  member: "Reports.reporttype",
                  operator: "equals",
                  values: ["PROPERTY"],
                },
              ],
            },
            chartType: "line",
            pivotConfig: {
              x: ["Reports.date.day"],
              y: ["measures"],
            },
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem title="Views on Rightmove">
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: {
              measures: ["Performancereports.sumDailyViewsLast30Days"],
              timeDimensions: [],
              order: [["Performancereports.date", "asc"]],
              filters: [],
              dimensions: ["Performancereports.date"],
            },
            chartType: "area",
            pivotConfig: {},
            dateFormat: "DD. MMM",
          },
        })}
      </DashboardItem>
      <DashboardItem title="Views on Greenacres">
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: getViewCount("greenacres"),
        })}
      </DashboardItem>
      <DashboardItem title="Views on France property shop">
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: getViewCount("francepropertyshop"),
        })}
      </DashboardItem>
      <DashboardItem title="Daily views for all the platforms">
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: dailyViewAllPlatformQuery,
            chartType: "area",
            pivotConfig: {},
            dateFormat: "DD. MMM",
            chartTitle: "Daily views for all the platforms",
          },
        })}
      </DashboardItem>
      <DashboardItem title="Daily views by platforms">
        {ChartRenderer({
          displaySerie,
          setDisplaySerie,
          vizState: {
            query: dailyViewByPlatformQuery,
            chartType: "area",
            pivotConfig: {},
            dateFormat: "DD. MMM",
            chartTitle: "Daily views by platforms",
          },
        })}
      </DashboardItem>
    </Dashboard>
  );
};

export default DashboardAdminPage;
