import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const staticOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    styledMode: true,
    spacingRight: 20,
    spacingLeft: 20,
    spacingBottom: 20,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: null,
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  accessibility: {
    point: {
      valueSuffix: '',
    },
  },
  plotOptions: {
    pie: {
      shadow: false,
      center: ['50%', '50%'],
      colors: ["#1890ff", "#141446", "#7A77FF", "#C6878F", "#B79D94", "#982649", "#F7ACCF", "#53F4FF"]
    },
  },
  series: [
    {
      name: 'count',
      colorByPoint: true,
      size: '80%',
      innerSize: '50%',
    },
  ],
};

export default ({ data }) => {
  const [options, setOptions] = useState({});
  useEffect(() => {
    setOptions({
      ...staticOptions,
      series: [
        {
          ...staticOptions.series[0],
          data: data,
        },
      ],
    });
  }, [data]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};