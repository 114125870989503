import React, { useState, useEffect } from "react";
import Map from "../components/Map";
import * as d3 from "d3";
import { useCubeQuery } from "@cubejs-client/react";
import { DashboardItem } from "../components/Utils";
import * as MapUtils from "./MapUtils";
import { ChartDescription } from "../components/Utils";

const whiteHex = "#FFFFFF";

const query = {
  dimensions: ["Agents.department"],
  timeDimensions: [],
  order: {
    "Agents.count": "desc",
  },
  measures: ["Agents.count"],
};

const quantile = d3
  .scaleQuantile()
  .domain([0, 20])
  // TODO: change by a d3 range, and change opacity instead of color
  .range([
    "#E2E6BD",
    "#F1DE81",
    "#F6C971",
    "#EEAB65",
    "#DA8459",
    "#B9534C",
    "#8E133B",
  ]);

const AgentMapPage = (props) => {
  const [geographies, setGeographies] = useState([]);
  const [agents, setAgents] = useState([]);
  const { resultSet, isLoading } = useCubeQuery(query);

  const mouseoverFunction = (department, mouseEvent) => {
    let mouseCoordinate = d3.pointer(mouseEvent);
    let numberAgents = 0;

    if (agents[department.properties.code] !== undefined) {
      numberAgents = agents[department.properties.code].nbAgents;
    }
    MapUtils.highlightDepartment(department);
    MapUtils.showDialog(
      "Department",
      "agents",
      department,
      numberAgents,
      mouseCoordinate
    );
  };

  const mouseoutFunction = () => {
    MapUtils.resetHighlight();
    MapUtils.hideDialog();
  };

  const getColor = (department) => {
    if (agents[department.properties.code] !== undefined) {
      return quantile(agents[department.properties.code].nbAgents);
    } else {
      return whiteHex;
    }
  };

  const getAgents = () => {
    var agentNumberByDepObj = {};
    resultSet.loadResponses[0].data.forEach((element) => {
      agentNumberByDepObj[element["Agents.department"]] = {
        nbAgents: element["Agents.count"],
      };
    });
    setAgents(agentNumberByDepObj);
  };

  useEffect(() => {
    if (!isLoading && resultSet) {
      getAgents();
      d3.json("departements.json").then((departments) => {
        setGeographies(departments.features);
      });
    }
  }, [isLoading, resultSet]);

  return (
    <DashboardItem
      title={[
        props.title,
        ChartDescription(props.description,
          "right"
        ),
      ]}
      size={12}
      scrollX={true}>
      <Map
        geographies={geographies}
        mouseoverFunction={mouseoverFunction}
        mouseoutFunction={mouseoutFunction}
        getColor={getColor}
      />
    </DashboardItem>
  );
};

export default AgentMapPage;
